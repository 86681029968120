<script setup lang="ts">
import { countFormatter, dateFormatter, fieldDesign, rules } from '@/const';
import { computed, ref } from 'vue';
import CurrencySelector from './CurrencySelector.vue';
import { nextTick } from 'vue';
import { watch } from 'vue';
import Decimal from 'decimal.js';
import isNil from 'lodash/isNil';
import { useAccountStore } from '@/stores/accountStore';

const accountStore = useAccountStore();

const emit = defineEmits([
  'update:modelValue',
  'update:currencyCodeValue'
]);

const props = defineProps({
  modelValue: null,
  currencyCodeValue: null,
  label: {
    type: String,
  },
  helpText: {
    type: String,
    default: null,
  },
  helpPosition: {
    type: String,
    default: 'append',
  },
  hint: {
    type: String,
    default: null,
  },
  rules: {
    type: Array,
    default: () => [],
  },
  currencyOptions: {
    type: Array,
    default: null,
  },
  hideCurrency: {
    type: Boolean,
    default: false,
  },
  allCurrencies: {
    type: Boolean,
    default: false,
  },
  calculated: {
    type: Number,
    default: null,
  },
  originalValue: {
    type: Number,
    default: null,
  },
  design: {
    type: Object,
    default: () => ({}),
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  holdingId: {
    type: Number,
    default: null,
  },
  date: {
    type: String,
    default: null,
  },
});

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', updateField(value))
  }
});

const balanceResponse = ref(null);

watch(() => props.calculated, (newValue) => {
  const diff = Math.abs(Math.abs(value.value) - Math.abs(newValue))
  if (isNaN(diff) || diff > 0.01) {
    value.value = newValue
  }
});

watch(() => [props.holdingId, props.date], async ([newHoldingId, newDate]) => {
  if (newHoldingId && newDate) {
    balanceResponse.value = await accountStore.getBalanceNumber(newHoldingId as number, newDate as string)
  } else {
    balanceResponse.value = null;
  }
}, { immediate: true });

const updateField = (newValue: any) => {
  if (isNil(newValue) || typeof newValue !== 'string') {
    return
  }

  newValue = newValue.replace(/,/g, '.')
  
  if ((newValue.match(/\./g)||[]).length > 1 && ['.',','].includes(newValue[newValue.length - 1])) {
    newValue = newValue.slice(0, -1)
  }

  newValue = newValue
    .replace(/,/g, '.')
    .replace(/[^\d.-]/g,'')
    .replace(/\.+(?=[^.]*\.)/g, '')

  return newValue
}

const inputRef = ref(null);

const currencyCodeValue = computed({
  get() {
    return props.currencyCodeValue
  },
  async set(value) {
    nextTick(() => {
      inputRef.value?.validate()
    })
    emit('update:currencyCodeValue', value)
  }
});

const requireCurrency = () => {
  return props.hideCurrency || !!currencyCodeValue.value || 'Currency is required';
};

const details = computed(() => {
  if (props.calculated !== null && props.originalValue !== null && props.calculated !== props.originalValue) {
    const diff = new Decimal(props.modelValue).minus(new Decimal(props.originalValue)).toNumber()
    return `Esialgne väärtus oli: ${props.originalValue}, erinevus: ${diff}`
  }
  return ''
});

const currencyOptions = computed(() => {
  const list = props.currencyOptions ?? []
  if (currencyCodeValue.value === 'GBX') {
    list.push({ title: 'GBX', value: 'GBX' })
    list.push({ title: 'GBP', value: 'GBP' })
  }
  return list
});

const allRules = computed(() => {
  if (props.disabled) return []
  return [...props.rules, rules.value.amount, requireCurrency]
});

const onSetBalance = () => {
  emit('update:modelValue', balanceResponse.value)
};
</script>


<template>
  <div style="flex:1;">
    <v-text-field
      v-bind="({...fieldDesign, ...design} as any)"
      :rules="allRules"
      type="text" 
      v-model="value"
      ref="inputRef"
      :label="props.label"
      autocomplete="no-lastpass"
      :hint="hint"
      :disabled="disabled"
      persistent-placeholder
    >
      <template v-slot:append-inner v-if="!hideCurrency">
        <currency-selector
          v-model="currencyCodeValue"
          :currency-options="currencyOptions"
          :allCurrencies="props.allCurrencies"
        />
      </template>
      <template v-slot:append-inner v-else>
        <slot name="append-inner" />
      </template>
      <template v-slot:append v-if="helpText && props.helpPosition === 'append'">
        <HoverHelpIcon size="24" slot="append" :helpText="helpText" />
      </template>
      <template v-slot:details v-if="details">
        <slot name="details">
          {{ details }}
        </slot>
      </template>
      <template v-slot:label>
        {{ props.label }}
        <HoverHelpIcon
          color="lightText"
          v-if="helpText && props.helpPosition === 'label'"
          size="16"
          :helpText="helpText"
        />
      </template>
    </v-text-field>
    <div class="d-flex" v-if="holdingId && !isNil(balanceResponse)">
      <div class="font-weight-light text-caption mt-1" style="line-height: 1.1;">
        {{ $t('label.quantity') }}: <a @click="onSetBalance" href="#" class="text-primary font-weight-medium">{{ countFormatter(balanceResponse) }}</a> @ {{ dateFormatter(date) }}
      </div>
    </div>
  </div>
</template>