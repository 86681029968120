<script setup lang="ts">
import { ref } from 'vue';
import { fieldDesign } from '@/const';
import { usePortfolioStore } from '@/stores/portfolioStore';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import FormContainer from '@/components/shared/FormContainer.vue';
import { useAppStore } from '@/stores/app';
import { watch } from 'vue';
import { useInvestmentStore } from '@/stores/investmentStore';
import type { PortfolioDtoType } from '~/portfolio.schema';
import InstrumentSearchField from '@/components/inputs/InstrumentSearchField.vue';
import { investmentTypeOptions, assetClassTypeOptions } from '@/utils/instrument.utils';
import { useI18n } from 'vue-i18n';
import IncomeConfigForm from '@/components/partial/IncomeConfigForm.vue';
import { FixedIncomeType, InstrumentType } from '~/enums';
import type { InvestmentCreateDtoType } from '~/investment.schema';
import type { InstrumentDtoType } from '~/instrument.schema';
import SyncWithCashAccountForm from './SyncWithCashAccountForm.vue';
import CustomGroupSelectFieldVue from '@/components/inputs/CustomGroupSelectField.vue';
import TabsLayout from '@/components/layout/TabsLayout.vue';
import ConfirmDialog from '@/components/shared/ConfirmDialog.vue';

type DeepPartial<T> = T extends object ? {
    [P in keyof T]?: DeepPartial<T[P]>;
} : T;

const portfolioStore = usePortfolioStore();
const investmentStore = useInvestmentStore();
const appStore = useAppStore();
const router = useRouter();
const { t } = useI18n();

const isLoading = ref(false);
const isDeleteLoading = ref(false);
const showSyncWithCashAccountModal = ref(false);
const confirmDialog = ref(null);

const portfolio = computed(() => portfolioStore.portfolio as PortfolioDtoType);
const investment = computed(() => investmentStore.investment);

const translations = computed(() => {
  return {
    title: t('investment_page.form.edit_investment_title'),
    instrumentHint: form.value.instrumentType ? t(`instrumentType.${form.value.instrumentType}.help`) : ''
  }
})

const form = ref<DeepPartial<InvestmentCreateDtoType>>({
  name: undefined,
  symbol: undefined,
  consolidationLabel: undefined,
  instrumentType: undefined,
  portfolioId: portfolio.value.id,
  assetClass: undefined,
  nominal: undefined,
  currencyCode: undefined,
  isArchived: false,
  fixedRateInstrumentConfig: {
    hasFixedIncome: false,
    taxRate: undefined,
    incomeType: FixedIncomeType.Bullet,
    incomeAmount: undefined,
    paymentFrequencyType: undefined,
    dateOfFirstPayment: undefined,
    dateOfLastPayment: undefined,
    currencyCode: undefined,
  },
  customGroups: [],
})

watch(portfolio, (portfolio) => {
  form.value.portfolioId = portfolio.id;
}, { immediate: true })

watch(() => investmentStore.investment, (investment: InstrumentDtoType) => {
  if (!investment || !investment.id) {
    return;
  }
  form.value.portfolioId = investment.portfolioId;
  form.value.currencyCode = investment.currencyCode;
  form.value.name = investment.name;
  form.value.symbol = investment.symbol;
  form.value.consolidationLabel = investment.consolidationLabel;
  form.value.instrumentType = investment.instrument.type;
  form.value.assetClass = investment.assetClass;
  form.value.nominal = investment.nominal;
  form.value.isArchived = investment.isArchived;
  form.value.fixedRateInstrumentConfig = investment.fixedRateInstrumentConfig || {
    hasFixedIncome: false,
    incomeType: FixedIncomeType.Bullet,
    currencyCode: investment.currencyCode,
  }
}, { immediate: true });

const onSubmit = async () => {
  isLoading.value = true;

  try {
    await investmentStore.updateInvestment(investmentStore.investment.id, form.value);
    await portfolioStore.getCategories();
    appStore.showNotice(t('investment_page.messages.updated'), 'success');
  } finally {
    isLoading.value = false;
  }
}

const onDelete = async () => {
  isDeleteLoading.value = true;

  try {
    await investmentStore.deleteInvestment(investmentStore.investment.id);
    appStore.showNotice(t('investment_page.messages.deleted'), 'success');
    router.push({ name: 'portfolio', params: { portfolioId: portfolio.value.id }});
  } finally {
    isDeleteLoading.value = false;
  }
}

const isFormLoading = computed(() => {
  return isDeleteLoading.value || investment.isLoading;
})

const triggerDelete = async () => {
  if (await confirmDialog.value?.open(t('label.confirm'), t('investment_page.form.delete_confirm'))) {
    onDelete();
  }
}

const tab = ref('settings');

const tabs = computed(() => {
  return [
    { label: t('portfolio_page.form.settings'), value: 'settings', icon: 'mdi-cog', disabled: false },
    { label: t('label.cashflow'), cashflow: 'goals', icon: 'mdi-stack-overflow', disabled: false },
    { label: t('investment_page.actions'), value: 'actions', icon: 'mdi-button-pointer', disabled: false },
  ]
})

const onExport = () => {
  document.location = '/api/v1/account/' + investmentStore.investment.id + '/export';
}
</script>


<template>
  <v-row>
    <v-col cols="12" lg="12">
      <FormContainer
        ref="formRef"
        :title="translations.title"
        :mode="'update'"
        @submit="onSubmit"
        @delete="onDelete"
        :is-form-loading="isFormLoading"
        :is-button-loading="isLoading"
        color="panel-heading-bg"
        :flavour="'tabs'"
        hide-delete
      >
        <TabsLayout :tabs="tabs" v-model="tab" v-if="investment.id">
          <v-tabs-window-item value="settings">
            <v-row>
              <v-col cols="12" lg="6">
                <InstrumentSearchField
                  :field-design="fieldDesign"
                  :disabled="true"
                  :label="t('label.instrument')"
                  v-model="investment.instrument"
                  :hide-create-manual="true"
                />
              </v-col>
              <v-col cols="12" lg="6">
                <v-select
                  v-bind="fieldDesign"
                  :items="investmentTypeOptions"
                  :label="t('label.instrument_type')"
                  v-model="form.instrumentType"
                  :disabled="true"
                />
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field
                  v-bind="fieldDesign"
                  type="text"
                  v-model="form.symbol"
                  :label="t('label.investment_code')"
                  :disabled="investment.instrument.isPublic"
                />
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field
                  v-bind="fieldDesign"
                  type="text"
                  v-model="form.name"
                  :label="t('label.investment_name')"
                />
              </v-col>
              <v-col cols="12" lg="6">
                <TextField
                  v-model="form.consolidationLabel"
                  :label="t('label.consolidation_label')"
                  :helpText="$t('instrument_page.consolidation_label_help')"
                />
              </v-col>
              <v-col cols="12" lg="6">
                <v-select
                  v-bind="fieldDesign"
                  :items="assetClassTypeOptions"
                  :label="t('label.asset_class')"
                  v-model="form.assetClass"
                />
              </v-col>
              <v-col cols="12" lg="6" v-if="[InstrumentType.Bond].includes(form.instrumentType)">
                <amount-field
                  v-model="form.nominal"
                  :label="t('label.bond_nominal')"
                  v-model:currency-code-value="form.currencyCode"
                />
              </v-col>
              <v-col cols="12" lg="12">
                <CustomGroupSelectFieldVue
                  v-bind="fieldDesign"
                  :label="t('label.custom_groups')"
                  v-model="form.customGroups"
                  :investmentId="`I${investment.instrument.id}`"
                />
              </v-col>
              <v-col cols="12">
                <v-checkbox
                  v-bind="fieldDesign"
                  v-model="form.isArchived"
                  :label="t('label.is_archived')"
                />
              </v-col>
            </v-row>
          </v-tabs-window-item>
          <v-tabs-window-item value="cashflow">
            <v-row>
              <IncomeConfigForm
                hide-title
                :form="form"
                :instrumentType="form.instrumentType"
              />
            </v-row>
          </v-tabs-window-item>
          <v-tabs-window-item value="actions">
            <v-row>
              <v-col cols="12">
                <div class="d-flex flex-column ga-2" style="max-width:300px;">
                  <v-btn
                    color="blue"
                    variant="outlined"
                    @click="onExport"
                  >
                    {{ t('label.export') }}
                  </v-btn>
                  <v-btn color="primary" variant="tonal" @click="showSyncWithCashAccountModal = true">
                    {{ $t('investment_page.sync_with_cash_account') }}
                    <template #append>
                      <HoverHelpIcon color="primary" size="16">
                        {{ $t('investment_page.sync_with_cash_account_help') }}
                      </HoverHelpIcon>
                    </template>
                  </v-btn>
                  <v-btn prepend-icon="mdi-delete" @click="triggerDelete" color="error" :disabled="isLoading" variant="tonal">
                    {{ $t('label.delete') }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-tabs-window-item>
        </TabsLayout>
      </FormContainer>
    </v-col>
  </v-row>
  <v-dialog :max-width="900" v-model="showSyncWithCashAccountModal" :scrollable="true">
    <SyncWithCashAccountForm @close="showSyncWithCashAccountModal = false" />
  </v-dialog>
  <ConfirmDialog ref="confirmDialog" />
</template>
