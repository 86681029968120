import { computed, type ComputedRef, type Ref } from "vue";
import { useI18n } from "vue-i18n";
import { useTheme } from "vuetify";
import { currencyFormatter, percentFormatter, colors, dayjsUtc } from '@/const'
import { useAppStore } from "./stores/app";
import { i18n } from "./plugins/i18n";
import dayjs from "dayjs";


const { t } = i18n.global

const defOpts = computed(() => ({ valueKey: 'percentage', height: 350 }))

function getUniqueColor(index, tone = 0) {
  const numColors = colors.length;

  if (index < numColors) {
    return colors[index]; // Use the predefined color
  }

  // Generate a new unique color (e.g., using HSL)
  const hue = (index * 137.508) % 360; // Golden angle approximation
  return `hsl(${hue}, 70%, 50%)`;
}

function getRangeColour(value: number) {
  if (value >= 3) {
    return '#30cc5a';
  } else if (value >= 2) {
    return '#2f9e4f';
  } else if (value >= 1) {
    return '#35764e';
  } else if (value >= 0.5) {
    return '#3d5451';
  } else if (value >= 0.1) {
    return '#3f4c54';
  } else if (value <= -3) {
    return '#f63538';
  } else if (value <= -2) {
    return '#bf4045';
  } else if (value <= -1) {
    return '#8b444e';
  } else if (value <= -0.5) {
    return '#4f4554';
  } else {
    return '#414554';
  }
}

function formatCode (code: string) {
  return code?.split(':')?.[0]
}

function formatReturn (value: number) {
  let number = value.toFixed(2) + '%'
  if (value > 0) {
    number = '+' + number
  }
  return number
}

function nameFormatter (item: any) {
  if (item.consolidate === 'consolidate') {
    const allSame = item.consolidation?.every(i => i.code === item.code)
    if (allSame) {
      return formatCode(item.code)
    }
  }
  let name = (item.consolidate === 'instrument' && item.instrumentType !== 'cash' ? formatCode(item.code) : item.title) ?? item.name
  if (name.length > 100) {
    name = name.substring(0, 7).trim() + '...'
  }
  return name
}

function mapTreemapLevelItem (item: any, valueKey: any, opts: any, n: number, i: number = 0, hasChildren: boolean = false) {
  const endValue = item.value;
  const maxValue = item.maxValue;
  const totalGain = item.openAndClosedPositions?.totalGain;
  const totalReturn = item.openAndClosedPositions?.totalReturn;
  const valueForRange = totalReturn === 0 && totalGain > 0 && Math.max(endValue, maxValue) === 0 ? 1 : totalReturn;
  
  const color = opts.mode === 'performance'
    ? getRangeColour(valueForRange * 100)
    : getUniqueColor(n, i)

  return {
    name: nameFormatter(item),
    title: item.title || item.name,
    value: typeof valueKey === 'function' ? valueKey(item) : item[valueKey],
    color: hasChildren ? 'transparent' : color,
    states:{
      hover: {
        fill: color
      }
    },
    totalReturn: totalReturn * 100,
    totalGain,
    endValue,
    ppp: item.percentage
  }
}

export function addLogo (chart: any, isDark: boolean) {
  // Add the logo image
  chart.renderer.image(
    `https://static.portfellow.com/portfellow-${isDark ? 'w' : 'b'}.svg?v=3`, // URL of the logo image
    chart.chartWidth - 110, // X position (adjust as needed)
    chart.chartHeight - 25, // Y position (adjust as needed)
    100, // Width of the logo
    25 // Height of the logo
  )
}

export function useAllocationChart (groups: Ref<any>, _opts: ComputedRef<{
  valueKey?: string,
  dataLabels?: boolean,
  isPercentage?: boolean | Ref<boolean>,
  name?: string,
  pointFormat?: string,
  exporting?: boolean,
  centerText?: () => string,
  height?: number
  currencyCode?: string
  compact?: boolean
}> = defOpts) {
  const { t } = useI18n();
  const theme = useTheme();

  const chartOptions = computed(() => {
    const opts = _opts.value
    const list = groups.value.filter((group: any) => group.value > 0);
    const isDark = theme.current.value.dark;
    const isPercentage = opts.isPercentage ?? false;

    return {
      chart: {
        type: 'pie',
        height: opts.height,
        backgroundColor: 'transparent',
        events: {
          load: function() {
            addLogo(this);
          },
          fullscreenOpen: function() {
            // Change background color when fullscreen is opened
            this.update({
                chart: {
                    backgroundColor: isDark ? '#00000' : '#FFFFFF' // Fullscreen chart background color
                }
            });
          },
          fullscreenClose: function() {
              // Revert background color when fullscreen is closed
              this.update({
                  chart: {
                      backgroundColor: 'transparent' // Revert to original background color
                  }
              });
          },
          render: function () {
              var chart = this,
                  centerX = chart.plotLeft + (chart.plotWidth / 2),
                  centerY = chart.plotTop + (chart.containerBox.height / 2) - 10;

              // Remove existing text (to avoid duplication on redraw)
              if (chart.centerText) {
                  chart.centerText?.destroy();
              }

              // Add text in the center
              chart.centerText = chart.renderer.text(opts.centerText?.(), centerX, centerY)
                  .attr({
                      'text-anchor': 'middle', // Center the text
                      zIndex: 5
                  })
                  .css({
                    fontSize: '20px',
                    color: isDark ? 'white' : '#0C4160'
                  })
                  .add();
          },
        }
      },
      exporting: {
        enabled: opts.exporting ?? false,
        buttons: {
          contextButton: {
            align: 'right',  // Horizontal alignment (left, center, right)
            verticalAlign: 'top',  // Vertical alignment (top, middle, bottom)
            x: -10,  // Horizontal offset
            y: 0  // Vertical offset
          }
        },
        chartOptions: {
          chart: {
            backgroundColor: '#FFFFFF',
          }
        }
      },
      credits: {
        enabled: false,
        text: 'portfellow.com',
        href: 'http://www.example.com'
      },
      title: {
        text: '',
      },
      tooltip: {
        pointFormat: opts.pointFormat ?? (isPercentage ? `{series.name}: <b>{point.percentage:.1f}%</b>` :  '{series.name}: <b>{point.value}</b>'),
        pointFormatter: function () {
          let value = '';
          if (isPercentage) {
            value = percentFormatter(this.percentage / 100)
          } else {
            value = currencyFormatter(this.y, opts.currencyCode)
          }

          return `<b>${value}</b>`
        }
      },
      legend: {
        enabled: !opts.compact,
        align: 'center',
        verticalAlign: 'bottom',
        layout: 'horizontal',
        itemWidth: opts.compact ? 100 : undefined,
        floating: false,
        itemStyle: {
          color: isDark ? 'white' : '#0C4160'
        },
        x: 0,
        y: 20
      },
      plotOptions: {
        pie: {
          innerSize: '60%',
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: opts.dataLabels ?? true,
            format: '{point.percentage:.1f}%',
            style: {
              color: isDark ? 'white' : '#0C4160',
              fontSize: '16px'
            }
          },
          showInLegend: true,
        }
      },
      navigation: {
        buttonOptions: {
            verticalAlign: 'bottom',
            y: 0
        }
      },
      series: [{
          name: opts.name ?? t('report.percentage'),
          data: list.map((group: any) => {
            const dashed = group.value < 0
            const y = Math.round(group[opts.valueKey] * (isPercentage ? 10000 : 1)) / (isPercentage ? 100 : 1)

            return {
              name: group.title ?? group.name,
              dashStyle: dashed ? 'longdash' : undefined,
              borderColor: dashed ? 'red' : undefined,
              color: dashed ? 'rgba(255, 0, 0, 0.5)' : undefined,
              y: Math.abs(y),
              value: y
            }
          })
      }]
    }
  });

  return {
    chartOptions
  }
}

export function useWaterfallChart (groups: Ref<any>, _opts: ComputedRef<{
  valueKey?: string,
  dataLabels?: boolean,
  isPercentage?: boolean | Ref<boolean>,
  name?: string,
  pointFormat?: string,
  exporting?: boolean,
  centerText?: () => string,
  height?: number
  currencyCode?: string
  compact?: boolean
}> = defOpts) {
  const { t } = useI18n();
  const theme = useTheme();
  const app = useAppStore();

  const chartOptions = computed(() => {
    const opts = _opts.value
    const list = groups.value;
    const isDark = theme.current.value.dark;
    const isPercentage = opts.isPercentage ?? false;

    const items = list.map((group: any) => {
      const y = Math.round(group[opts.valueKey] * (isPercentage ? 10000 : 1)) / (isPercentage ? 100 : 1)

      return {
        name: group.title ?? group.name,
        color: y < 0 ? colors[5] : colors[0],
        y: y,
        value: y
      }
    });

    const total = items.reduce((acc, item) => acc + item.y, 0);

    return {
      chart: {
        type: 'waterfall',
        height: opts.height,
        backgroundColor: 'transparent'
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
        text: 'portfellow.com',
        href: 'http://www.example.com'
      },
      exporting: {
        enabled: false,
      },
      tooltip: {
        pointFormat: opts.pointFormat ?? (isPercentage ? `{series.name}: <b>{point.percentage:.1f}%</b>` :  '{series.name}: <b>{point.value}</b>'),
        pointFormatter: function () {
          let value = '';
          if (isPercentage) {
            value = percentFormatter(this.percentage / 100)
          } else {
            value = currencyFormatter(this.y, opts.currencyCode)
          }

          return `<b>${value}</b>`
        }
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        type: 'category',
        labels: {
          enabled: true,
          style: {
            color: isDark ? '#ffffff' : '#0C4160'
          }
        },
        gridLineColor: isDark ? '#32323e' : 'rgb(222, 231, 248)',
        lineColor: isDark ? '#495057' : 'rgb(222, 231, 248)'
      },
      yAxis: {
        title: {
          text: ''
        },
        labels: {
          enabled: true,
          style: {
            color: isDark ? '#ffffff' : '#0C4160'
          }
        },
        lineColor: isDark ? '#495057' : 'rgb(222, 231, 248)',
        lineWidth: 1,
        gridLineColor: isDark ? '#495057' : 'rgb(222, 231, 248)',
        minorGridLineWidth: 0,
      },
      plotOptions: {
        waterfall: {
          borderColor: 'rgba(255,255,255,0.1)',
          pointPadding: 0,
          groupPadding: 0.1,
          borderWidth: 1
        },
      },
      series: [{
          name: opts.name ?? t('report.percentage'),
          data: items.filter(i => i.y > 0).concat([{
            name: t('label.gross_value'),
            isSum: true,
            color: colors[1]
          }]).concat(items.filter(i => i.y < 0)).concat([{
            name: t('label.net_value'),
            isIntermediateSum: true,
            color: colors[3]
          }]),
          dataLabels: {
            enabled: true,
            formatter: function () {
              if (this.point.isSum || this.point.isIntermediateSum || this.y < 0) {
                if (app.hideBalances) {
                  return '?';
                }
                return currencyFormatter(this.y, opts.currencyCode, 0, 0)
              }
              return percentFormatter(this.y / total)
            }
          },
          pointPadding: 0
      }]
    }
  });

  return {
    chartOptions
  }
}

export function useTreemapChart (groups: Ref<any>, _opts: ComputedRef<{
  valueKey?: string | ((item: any) => number),
  dataLabels?: boolean,
  isPercentage?: boolean | Ref<boolean>,
  name?: string,
  pointFormat?: string,
  exporting?: boolean,
  centerText?: () => string,
  height?: number
  currencyCode?: string
  compact?: boolean
  mode?: 'allocation' | 'performance'
}> = defOpts) {
  const chartOptions = computed(() => {
    const opts = _opts.value
    const list = groups.value ?? [];
    let points = [];
    try {
      points = list.reduce((collect: any, group: any, n: number) => {
        collect.push({
          id: group.key,
          ...mapTreemapLevelItem(group, opts.valueKey, _opts.value, n, undefined, group.items?.length),
        });
        if (group.items?.length) {
          group.items.forEach((item: any, i: number) => {
            collect.push({
              parent: group.key,
              id: `${group.key}-${i}`,
              ...mapTreemapLevelItem(item, opts.valueKey, _opts.value, n, i, item.items?.length),
            });
            if (item.items?.length) {
              item.items.forEach((subitem: any, j: number) => {
                collect.push({
                  parent: `${group.key}-${i}`,
                  id: `${group.key}-${i}-${j}`,
                  ...mapTreemapLevelItem(subitem, opts.valueKey, _opts.value, n, j, false),
                });
              });
            }
          });
        }
        return collect;
      }, []);
    } catch (e) { 
      console.log('error', e)
    }

    return {
      chart: {
        type: 'treemap',
        height: opts.height,
        backgroundColor: 'transparent',
        margin: [0,0,0,0],
        spacingTop: 0,
        spacingRight: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        plotBorderWidth: 0,
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
        text: 'portfellow.com',
        href: 'http://www.example.com'
      },
      exporting: {
        enabled: false,
      },
      tooltip: {
        backgroundColor: 'rgba(0,0,0,0)',
        shadow: false,
        borderWidth: 0,
        useHTML: true,
        style: {
          zIndex: 100
        },
        formatter: function() {
          // @ts-ignore
          const point = this.point as any;
          const currencyCode = opts.currencyCode as string;

          const items = [{
            title: point.title,
          }, {
            title: t('portfolio.value'),
            value: currencyFormatter(point.endValue, currencyCode)
          }, {
            title: t('portfolio.valuePercentage'),
            value: (point.ppp * 100).toFixed(2) + '%'
          }, {
            title: t('performance.total_gain'),
            value: formatReturn(point.totalReturn)
          }, {
            title: t('performance.total_gain'),
            value: currencyFormatter(point.totalGain, currencyCode)
          }]

          const rows = items.map(item => {
            if (!item.value) {
              return '<span class="tooltip-heading">' + item.title + '</span>';
            } else {
              return `<span class="tooltip-row">
                <span class="tooltip-row__title">${item.title}:</span>
                <span class="tooltip-row__value">${item.value}</span>
              </span>`;
            }
          }).join('');

          return `<p class="chart-tooltip-container">${rows}</p>`;
        }
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        treemap: {
          borderRadius: 1,
          dataLabels: {
            enabled: true,
          },
        }
      },
      series: [{
        name: 'Portfolio',
        type: 'treemap',
        breadcrumbs: {
          floating: true,
          buttonTheme: {
            style: {
              color: "#fff",
            },
            states: {
              hover: {
                style: {
                  color: "#fff",
                },
                fill: "#fdd714"
              },
              select: {
                style: {
                  color: "#fff",
                }
              }
            }
          },
        },
        states: {
          hover: {
            borderColor: '#fdd714',
          }
        },
        data: points,
        layoutAlgorithm: 'squarified',
        allowDrillToNode: true,
        borderWidth: 1,
        borderColor: '#000',
        clip: false,
        dataLabels: {
          position: 'center',
          verticalAlign: 'middle',
          useHTML: true,
          enabled: true,
          allowOverlap: true,
          padding: 2,
          style: {
            color: '#fff',
          },
          formatter: function() {
            if (!this.point.shapeArgs) {
              return;
            }
            if (this.point.node.children.length > 0) {
              if (this.point.node.children.length <= 1) {
                return '';
              }
              return '<div class="category-label">' + this.key + '</div>';
            }
            if (this.point.shapeArgs.width < 20 || this.point.shapeArgs.height < 10) {
              return '';
            }
            const val = opts.mode === 'performance' ? formatReturn(this.point.totalReturn) : '';
            if(this.point.shapeArgs.width > this.point.shapeArgs.height) {
              return '<div style="width:' + (this.point.shapeArgs.width - 4) +'px;" class="main-label"><div class="main-label__title" style="font-size:' + ((this.point.shapeArgs.height)/6) + 'px">' + this.key + '</div><div class="main-label__changes" style="font-size:' + ((this.point.shapeArgs.height)/6) + 'px">' + val + '</div></div>';
            } else {
              return '<div style="width:' + (this.point.shapeArgs.width - 4) +'px;"  class="main-label"><div class="main-label__title" style="font-size:' + ((this.point.shapeArgs.width)/6) + 'px">' + this.key + '</div><div class="main-label__changes" style="font-size:' + ((this.point.shapeArgs.width)/6) + 'px">' + val + '</div></div>';
            }
          }
        },
        levels: [{
          level: 1,
          dataLabels: {
            enabled: true,
            zIndex: 1,
          },
          borderWidth: 4,
          levelIsConstant: false
        }, {
          level: 2,
          dataLabels: {
            zIndex: 2,
            style: {
              fontSize: '1px'
            }
          }
        }, {
          level: 3,
          dataLabels: {
            zIndex: 3,
            style: {
              fontSize: '1px'
            }
          }
        }],
        accessibility: {
          exposeAsGroupOnly: true
        },
      }]
    }
  });

  return {
    chartOptions
  }
}

export function useGroupByPeriod (series: {data: {x: number, y: number}[]}[]) {
  const min = series.reduce((acc, serie) => Math.min(acc, serie.data[0]?.x), Infinity);
  const max = series.reduce((acc, serie) => Math.max(acc, serie.data[serie.data.length - 1]?.x), -Infinity);
  const diffInMonths = dayjs(max).diff(dayjs(min), 'month');
  const step = diffInMonths > 12 ? 'year' : 'month';
  
  const newSeries = series.map(serie => {
    return {
      ...serie,
      data: serie.data.reduce<{x: number, y: number}[]>((acc, point) => {
        const date = dayjs.utc(point.x).startOf(step).valueOf();
        const existing = acc.find(p => p.x === date);
        if (existing) {
          existing.y += point.y;
        } else {
          acc.push({ x: date, y: point.y });
        }
        return acc;
      }, []).map((point, i) => {
        return {
          x: i,
          category: point.x,
          y: Math.abs(point.y) < 0.01 ? null : point.y
        }
      })
    }
  });

  const categories = newSeries[0].data.map(point => dayjs(point.category).format(step === 'year' ? 'YYYY' : 'MMM YYYY'));

  return {
    series: newSeries,
    categories
  }
}

export function useBarChart (data: Ref<any>, _opts: ComputedRef<{}>) {
  const theme = useTheme();

  const chartOptions = computed(() => {
    const isDark = theme.current.value.dark;
    const categories = data.value.map((item: any) => {
      return {
        name: item.name,
        isGroup: item.isGroup,
        isConsolidation: item.isConsolidation,
        level: item.level
      }
    })
    return {
      chart: {
        type: 'bar',
        backgroundColor: 'transparent',
        height: data.value.length * 40 + 100,
        events: {
          render: function() {
            const chart = this;
            const series = chart.series[0];
            const yAxis = chart.yAxis[0];
            
            const y0 = yAxis.toPixels(0, true);
            for (const { dataLabel, negative } of series.points) {
              if (negative) {
                dataLabel.attr({
                  x: y0,
                  'text-anchor': 'start'
                });
              } else {
                dataLabel.attr({
                  x: y0 - 11,
                  'text-anchor': 'end'
                });
              }
            }
          }
        }
      },
      credits: {
        enabled: false,
        text: 'portfellow.com',
        href: 'http://www.example.com'
      },
      title: {
        text: '',
      },
      yAxis: {
        plotLines: [{
          color: isDark ? '#495057' : 'rgb(222, 231, 248)',
          value: 0,
          zIndex: 4
        }],
        labels: {
          enabled: false
        },
        gridLineWidth: 0,
        title: {
          text: ''
        },
      },
      xAxis: {
        categories: categories.map((item: any) => item.name),
        lineWidth: 1,
        labels: {
          useHTML: true,
          formatter: function () {
            const index = this.pos;
            const category = categories[index];
            if (category.isGroup) {
              let style = 'font-weight:bold;font-size:120%;';
              if (category.level === 1) {
                style += 'font-style:italic;';
              }
              return `<span style="${style}">${category.name}</span>`;
            }
            if (category.isConsolidation) {
              return `<span style="font-style:italic;">${category.name}</span>`;
            }
            return `<span>${category.name}</span>`;
          },
          style: {
            color: isDark ? '#ffffff' : '#0C4160'
          }
        },
        gridLineColor: isDark ? '#32323e' : 'rgb(222, 231, 248)',
        lineColor: isDark ? '#495057' : 'rgb(222, 231, 248)'
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        bar: {
          borderWidth: 0,
          groupPadding:0.1,
          dataLabels: {
            enabled: true,
            format: '{#if point.negative}{point.y}% {else}{point.y}%{/if}'
          }
        }
      },
      series: [{
        data: data.value.map((item: any) => item.value),
        color: '#56ca00',
        negativeColor: '#f64e60',
        dataLabels: {
          enabled: true,
          style: {
            color: 'white',
            textOutline: 'transparent'
          }
        },
      }]
    }
  });

  return {
    chartOptions
  }
}