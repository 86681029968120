<script setup lang="ts">
import { ref } from 'vue';
import { useAuthStore } from '@/stores/authStore';
import { computed } from 'vue';
import { watch } from 'vue';
import { useAppStore } from '@/stores/app';

const emit = defineEmits(['showNotifications', 'close']);

const authStore = useAuthStore();
const appStore = useAppStore();
const isDarkTheme = ref(appStore.theme === 'MainThemeDark');

watch(() => isDarkTheme.value, (newVal) => {
  if (newVal) {
    appStore.setTheme('MainThemeDark')
  } else {
    appStore.setTheme('MainTheme')
  }
});

const showUpgrade = computed(() => {
  return authStore.user.subscription?.tier === 'free'
})

const locales = computed(() => {
  return [
    { title: 'English', subtext: 'UK', value: 'en' },
    { title: 'Eesti', subtext: 'ET', value: 'et' },
  ]
})

const onShowNotifications = () => {
  emit('showNotifications');
  emit('close');
}

const notificationCount = computed(() => {
  return authStore.unreadNotificationCount;
});
</script>

<template>
  <!-- ---------------------------------------------- -->
  <!-- profile DD -->
  <!-- ---------------------------------------------- -->
  <div class="profile-menu pa-4" v-if="authStore.user">
    <h4 class="mb-n1">{{ $t('header.welcome') }} <span class="font-weight-regular">{{ authStore.user.firstName }}</span></h4>
    <perfect-scrollbar style="height: auto; max-height: 560px">
      <div class="bg-blue-lighten-1 rounded-md pa-5 my-3" v-if="showUpgrade">
        <h4>
         {{ $t('header.upgrade_to_premium') }}
        </h4>
        <v-btn color="white" variant="flat" class="mt-2" :to="{ name: 'subscribe' }">
          {{ $t('header.upgrade') }}
        </v-btn>
      </div>

      <v-divider class="mt-2 mb-1"></v-divider>

      <v-list density="compact">
        <v-list-item @click="onShowNotifications" color="secondary" rounded="md">
          <v-list-item-title class="text-body-1 d-flex align-center ga-2">
            <v-icon color="secondary" size="24">mdi-bell-outline</v-icon>
            <span>
              {{ $t('header.notifications') }}
            </span>
            <v-badge
              v-if="notificationCount"
              color="warning"
              :content="notificationCount"
              inline
            />
          </v-list-item-title>
        </v-list-item>
        <v-list-item :to="{name: 'user-account-settings'}" color="secondary" rounded="md">
          <v-list-item-title class="text-body-1 d-flex align-center ga-2">
            <v-icon color="secondary" size="24">mdi-account-circle-outline</v-icon>
            <span>{{ $t('header.account_settings') }}</span>
          </v-list-item-title>
        </v-list-item>
        <v-list-item :to="{name: 'list-groups'}" color="secondary" rounded="md">
          <v-list-item-title class="text-body-1 d-flex align-center ga-2">
            <v-icon color="secondary" size="24">
              mdi-table-settings
            </v-icon>
            <span>
              {{ $t('label.custom_groups') }}
            </span>
          </v-list-item-title>
        </v-list-item>
        <v-list-item :to="{name: 'import-links'}" color="secondary" rounded="md">
          <v-list-item-title class="text-body-1 d-flex align-center ga-2">
            <v-icon color="secondary" size="24">
              mdi-sync
            </v-icon>
            <span>
              {{ $t('label.account_links') }}
            </span>
          </v-list-item-title>
        </v-list-item>
        <v-list-item :to="{name: 'plans-and-billing-settings'}" color="secondary" rounded="md">
          <v-list-item-title class="text-body-1 d-flex align-center ga-2">
            <v-icon color="secondary" size="24">mdi-credit-card-outline</v-icon>
            <span>{{ $t('header.plans_and_billing') }}</span>
          </v-list-item-title>
        </v-list-item>
        <v-list-item :to="{name: 'support'}" color="secondary" rounded="md">
          <v-list-item-title class="text-body-1 d-flex align-center ga-2">
            <v-icon color="secondary" size="24">mdi-account-group-outline</v-icon>
            <span>{{ $t('header.community') }}</span>
          </v-list-item-title>
        </v-list-item>
        <v-list-item color="secondary" rounded="md">
          <v-list-item-title class="text-body-1 d-flex align-center ga-2">
            <v-icon color="secondary" size="24">mdi-theme-light-dark</v-icon>
            <v-switch @click.stop color="primary" v-model="isDarkTheme" class="text-body-1" :hide-details="true">
              <template v-slot:label>
                <label class="text-body-1">Dark Mode</label>
              </template>
            </v-switch>
          </v-list-item-title>
        </v-list-item>
        <template v-if="true">
          <v-divider class="mt-2 mb-1"></v-divider>
          <v-list-item color="secondary" rounded="md">
            <v-list-item-title class="text-body-1 d-flex align-center ga-2">
              <v-select
                variant="outlined"
                density="compact"
                v-model="$i18n.locale"
                :items="locales"
                item-text="title"
                item-value="value"
                class="text-body-1"
                :hide-details="true"
                @click.stop
              />
            </v-list-item-title>
          </v-list-item>
        </template> 
        <v-divider class="mt-2 mb-1"></v-divider>
        <v-list-item @click="authStore.logout()" color="secondary" rounded="md">
          <v-list-item-title class="text-body-1 d-flex align-center ga-2">
            <v-icon size="24" color="secondary">mdi-logout</v-icon>
            <span>{{ $t('header.logout') }}</span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </perfect-scrollbar>
  </div>
</template>

<style lang="scss">
.profile-menu {
  label {
    font-size: 16px;
  }
}
</style>
