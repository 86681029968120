<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useAuthStore } from '@/stores/authStore';
import { useAppStore } from '@/stores/app';
import { fieldDesign, rules } from '@/const';
import { useI18n } from 'vue-i18n';
import CookieConsentVue from './CookieConsent.vue';
import TabsLayout from '@/components/layout/TabsLayout.vue';
import FormContainer from '@/components/shared/FormContainer.vue';

const authStore = useAuthStore();
const appStore = useAppStore();
const isSubmitting = ref(false);
const { t } = useI18n();

const showDeleteAccountDialog = ref(false);
const isDeleteAccoutLoading = ref(false);
const currentPassword = ref('');
const showCookieConsentDialog = ref(false);

const form = ref({
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  language: undefined,
  disableHelp: false
});

watch(() => authStore.user, (user) => {
  form.value.firstName = user?.firstName;
  form.value.lastName = user?.lastName;
  form.value.email = user?.email;
  form.value.language = user?.language;
  form.value.disableHelp = user?.disableHelp;
}, { immediate: true });

const onSubmit = async () => {
  isSubmitting.value = true;
  try {
    await authStore.updateProfile(form.value);
    appStore.showNotice(t('settings_page.messages.account_updated'), 'success');
  } finally {
    isSubmitting.value = false;
  }
}

const deleteAccount = async () => {
  try {
    isDeleteAccoutLoading.value = true;
    await authStore.deleteAccount(currentPassword.value);
    authStore.logout();
    appStore.showNotice(t('settings_page.messages.account_deleted'), 'success');
  } finally {
    isDeleteAccoutLoading.value = false;
  }
}

const languages = [
  { title: 'Eesti', value: 'et' },
  { title: 'English', value: 'en' },
];


const tab = ref('settings');

const tabs = computed(() => {
  return [
    { label: t('portfolio_page.form.settings'), value: 'settings', icon: 'mdi-cog', disabled: false },
    { label: t('investment_page.actions'), value: 'actions', icon: 'mdi-button-pointer', disabled: false },
  ]
})
</script>

<template>
 <PageLayout :heading="$t('settings_page.profile_tab')">
    <v-row>
      <v-col cols="12">
        <FormContainer
          ref="formRef"
          :title="$t('settings_page.title')"
          :mode="'update'"
          @submit="onSubmit"
          :is-form-loading="isSubmitting"
          :is-button-loading="isSubmitting"
          color="panel-heading-bg"
          :flavour="'tabs'"
          hide-delete
        >
          <TabsLayout :tabs="tabs" v-model="tab">
            <v-tabs-window-item value="settings">
              <v-row>
                <v-col cols="12" lg="6">
                  <v-text-field
                    type="text"
                    v-bind="fieldDesign"
                    v-model="form.firstName"
                    :label="$t('label.first_name')"
                    :rules="[rules.required]"
                    required
                  />
                </v-col>
                <v-col cols="12" lg="6">
                  <v-text-field
                    type="text"
                    v-bind="fieldDesign"
                    v-model="form.lastName"
                    :label="$t('label.last_name')"
                    :rules="[rules.required]"
                    required
                  />
                </v-col>
                <v-col cols="12" lg="6">
                  <v-text-field
                    type="email"
                    v-bind="fieldDesign"
                    v-model="form.email"
                    :label="$t('label.email')"
                    disabled
                  />
                </v-col>
                <v-col cols="12" lg="6">
                  <v-select
                    v-model="form.language"
                    :items="languages"
                    :label="$t('label.language')"
                    v-bind="fieldDesign"
                  />
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    class="mt-n4"
                    v-bind="fieldDesign"
                    v-model="form.disableHelp"
                    :label="$t('settings_page.disable_help')"
                    :value="true"
                    density="compact"
                  />
                </v-col>
              </v-row>
            </v-tabs-window-item>
            <v-tabs-window-item value="actions">
              <v-row>
                <v-col cols="12">
                  <div class="d-flex flex-column ga-2" style="max-width:300px;">
                    <v-btn @click="showCookieConsentDialog = true" color="primary" variant="tonal">
                      {{ $t('settings_page.manage_cookies') }}
                    </v-btn>
                    <v-btn
                      variant="tonal"
                      color="error"
                      :loading="isDeleteAccoutLoading"
                      @click="showDeleteAccountDialog = true"
                    >
                      {{ $t('settings_page.delete_account') }}
                      <v-icon class="ml-2" right>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-tabs-window-item>
          </TabsLayout>
        </FormContainer>
      </v-col>
    </v-row>
  </PageLayout>
  <Dialog
    v-model:model-visible="showDeleteAccountDialog"
    :title="$t('settings_page.delete_account')"
    :confirm="{ show: true, label: $t('settings_page.delete_account'), color: 'warning' }"
    @confirm="deleteAccount"
  >
    <div class="d-flex flex-column ga-4">
      <p>
        {{ $t('settings_page.delete_account_info') }}
      </p>
      <p>
        {{ $t('settings_page.delete_account_password_info') }}
      </p>
      <v-text-field
        v-model="currentPassword"
        v-bind="(fieldDesign as any)"
        type="password"
        :label="$t('label.current_password')"
        :rules="[rules.required]"
        required
      />
    </div>
  </Dialog>
  <CookieConsentVue v-if="showCookieConsentDialog" v-model:model-visible="showCookieConsentDialog" />
</template>
