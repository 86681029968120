
<script setup lang="ts">
import { computed } from 'vue';
import { currencyFormatter } from '@/const';
import { InstrumentType } from '~/enums';
import UpgradeLock from '../UpgradeLock.vue';
import HideBalancesButtonVue from '../HideBalancesButton.vue';
import { useI18n } from 'vue-i18n';


const props = defineProps(['value', 'loading', 'subtitle', 'icon', 'config', 'summary', 'color'])
const { t } = useI18n()

const text = computed(() => {
  if (!props.summary) {
    return;
  }

  const isSameCurrency = props.summary.currencyCode === props.summary.baseCurrencyCode;
  const showQuantityInValue = props.config?.showQuantityInValue;
  const isRealestate = props.config?.type === InstrumentType.RealEstate;

  const parts = []

  if (isRealestate) {
    parts.push('<span>' + t('label.net_value') + ':</span>')
  }

  if (showQuantityInValue) {
    parts.push(currencyFormatter(props.summary.value, props.summary.baseCurrencyCode))
  } else if (isSameCurrency) {
    parts.push(currencyFormatter(props.summary.value, props.summary.baseCurrencyCode))
  } else {
    parts.push(currencyFormatter(props.summary.quantity, props.summary.currencyCode))
    parts.push(`=`)
    parts.push(currencyFormatter(props.summary.value, props.summary.baseCurrencyCode))
  }

  return parts.join(' ')
})

const secondLine = computed(() => {
  const isRealestate = props.config?.type === InstrumentType.RealEstate;
  if (!isRealestate || props.summary.groups.length < 1) {
    return;
  }

  const isSameCurrency = props.summary.currencyCode === props.summary.baseCurrencyCode;
  const parts = []

  parts.push('<span>' + t('label.gross_value') + ':</span>');
  const gross = props.summary.groups[0].items[0]

  if (isSameCurrency) {
    parts.push(currencyFormatter(gross.price, props.summary.baseCurrencyCode))
  } else {
    parts.push(currencyFormatter(props.summary.quantity, props.summary.currencyCode))
    parts.push(`=`)
    parts.push(currencyFormatter(props.summary.value, props.summary.baseCurrencyCode))
  }

  return parts.join(' ')
})

const color = computed(() => props.color ?? 'primary')
</script>

<template>
  <v-card elevation="4" :variant="$vuetify.theme.current.dark ? 'outlined' : 'text'" :class="`bg-${color} bubble-shape-sm bubble-warning overflow-hidden`">
    <v-card variant="outlined" class="border-0">
      <v-card-text>
        <div class="d-flex align-items-center justify-space-between">
          <div>
            <slot>
              <h3 class="text-h3" style="font-size:150% !important;" v-if="!props.loading && secondLine">
                <span class="font-weight-medium balance" v-html="secondLine" />
              </h3>
              <h3 class="text-h3 d-flex align-center" style="font-size:150% !important;" v-if="!props.loading">
                <span class="font-weight-medium balance-lg" v-html="text" />
                <HideBalancesButtonVue />
                <upgrade-lock v-if="summary?.isPartial" icon="mdi-exclamation" />
              </h3>
              <div v-else><v-progress-circular size="32" indeterminate color="white" /></div>
            </slot>
            <span class="text-medium-emphasis text-white">{{ props.subtitle }}</span>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<style lang="scss">
h3 span {
  font-weight: 300;
}
</style>