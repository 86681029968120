import { defineStore } from 'pinia';
import { router } from '@/router';
import { fetchWrapper } from '@/utils/helpers/fetch-wrapper';
import type { UpdateUserProfileDtoType, UserSubscriptionDtoType } from '~/user.schema';
import { FeatureFlag } from '~/enums';
import { SubscriptionTier } from '../../../backend/src/enums';
import debounce from 'lodash/debounce';
import { useAppStore } from './app';
import { i18n } from '@/plugins/i18n';
const { locale } = i18n.global;

const savePreferencesToServer = debounce((preferences: any) => {
  if (window.navigator.sendBeacon) {
    window.navigator.sendBeacon('/api/v1/auth/me/preferences', JSON.stringify(preferences));
  }
}, 5000);


export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    // @ts-ignore
    user: JSON.parse(localStorage.getItem('user')),
    limits: null,
    backendRefreshDone: false,
    returnUrl: '',
    // @ts-ignore
    userPreference: JSON.parse(localStorage.getItem('userPreference') || '{}'),
    notifications: [],
  }),
  getters: {
    hasChosenTier: (state) => ![SubscriptionTier.Undefined].includes(state.user?.subscription?.tier),
    hasAcceptedTerms: (state) => state.user?.termsAccepted,
    unconfirmedTransactions: (state) => state.user?.unconfirmedTransactions,
    tier: (state) => (state.user.subscription.tier ?? SubscriptionTier.Free) as SubscriptionTier,
    initials: (state) =>  state.user?.firstName?.charAt(0) + state.user?.lastName?.charAt(0),
    avatarUrl: (state) => `https://www.gravatar.com/avatar/${state.user?.emailHash}?d=identicon`,
    hasPaidSubscription: (state) => state.user?.subscription?.tier !== SubscriptionTier.Free,
    unreadNotificationCount: (state) => state.notifications.filter((n: any) => n.status === 0).length,
  },
  actions: {
    setUserPreference (key: string, value: any, portfolioId?: number) {
      if (portfolioId) {
        key = `${portfolioId}_${key}`;
      }

      // @ts-ignore
      this.userPreference[key] = value;
      localStorage.setItem('userPreference', JSON.stringify(this.userPreference));
      savePreferencesToServer(this.userPreference);
    },
    getUserPreference (key: string, defaultValue?: any, portfolioId?: number) {
      let portfolioSpecificValue = null;
  
      if (portfolioId) {
        const portfolioSpecific = `${portfolioId}_${key}`;
        portfolioSpecificValue = this.userPreference[portfolioSpecific];
      }

      return portfolioSpecificValue ?? this.userPreference[key] ?? defaultValue;
    },
    async login(email: string, password: string) {
      const user = await fetchWrapper.post(`/api/v1/auth/login`, { email, password });

      // update pinia state
      this.user = user;
      // store user details and jwt in local storage to keep user logged in between page refreshes
      localStorage.setItem('user', JSON.stringify(user));
      await this.getMe();
      // redirect to previous url or default to home page
      router.push(this.getReturnUrl(true) || '/');
    },
    async loginOtp(otpCode: string, rememberMe: boolean, requestToken: string) {
      const user = await fetchWrapper.post(`/api/v1/auth/login/otp`, { otpCode, rememberMe, requestToken });

      // update pinia state
      this.user = user;
      // store user details and jwt in local storage to keep user logged in between page refreshes
      localStorage.setItem('user', JSON.stringify(user));
      await this.getMe();
      // redirect to previous url or default to home page
      router.push(this.getReturnUrl(true) || '/');
    },
    async getMe () {
      try {
        const user = await fetchWrapper.get(`/api/v1/auth/me`);
        this.user = user;
        void this.getNotfications();
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('user_id', user.id);
        // @ts-expect-error
        window.newrelic?.setUserId(user.id.toString());
        // @ts-expect-error
        window.newrelic?.setApplicationVersion(__APP_VERSION__);
        // @ts-expect-error
        window.__APP_VERSION_BACKEND__ = user.version;
        const preferencesFromServer = JSON.parse(user.preferences);
        this.userPreference = preferencesFromServer || this.userPreference;
        if (preferencesFromServer.theme) {
          const appStore = useAppStore();
          appStore.setTheme(preferencesFromServer.theme);
        }
        if (preferencesFromServer.locale) {
          locale.value = preferencesFromServer.locale;
        }
        this.backendRefreshDone = true;
        return user;
      } catch (error: any) {
        if (['Authorization token expired', 'No Authorization was found in request.cookies'].includes(error.message)) {
          localStorage.removeItem('user');
          this.logout();
          return false;
        }
        return false;
      }
    },
    async getNotfications () {
      try {
        if (!this.user) {
          return;
        }
        const notifications = await fetchWrapper.get(`/api/v1/auth/me/notifications`);
        this.notifications = notifications;
      } catch (error) {
        return false;
      }
    },
    async markNotificationAsRead (notificationId: number, status: number) {
      try {
        const notification = this.notifications.find((n: any) => n.id === notificationId) as any
        if (notification) {
          notification.status = status;
        }
        await fetchWrapper.put(`/api/v1/auth/me/notifications/${notificationId}/read`, { status });
      } catch (error) {
        return false;
      }
    },
    async getMyLimits () {
      try {
        const limits = await fetchWrapper.get(`/api/v1/auth/me/limits`);
        this.limits = limits;
        return limits;
      } catch (error) {
        return false;
      }
    },
    async logout() {
      if (this.user) {
        this.user = null;
        localStorage.removeItem('user');
        router.push('/auth/login');
        window.postMessage('logout', '*');
        await fetchWrapper.get('/api/v1/auth/logout');
        document.location.reload();
      }
    },
    async register(form: any) {
      return fetchWrapper.post('/api/v1/auth/register', form)
    },
    async forgotPassword(email: string) {
      return fetchWrapper.post('/api/v1/auth/forgot-password', { email });
    },
    async resetPassword(token: string, password: string, confirmPassword: string) {
      return fetchWrapper.post('/api/v1/auth/reset-password', { token, password, confirmPassword });
    },
    async changePassword(form: any) {
      return fetchWrapper.post('/api/v1/auth/change-password', form);
    },
    async updateProfile(form: UpdateUserProfileDtoType) {
      return fetchWrapper.put(`/api/v1/user/${this.user.id}/profile`, form)
        .then(() => {
          return this.getMe();
        })
    },
    async getBillingInfo() {
      return fetchWrapper.get(`/api/v1/stripe/billing`)
        .then((response) => {
          return response;
        })
    },
    async getSubscriptionInfo(params: any): Promise<UserSubscriptionDtoType> {
      const query = new URLSearchParams(
        Object.assign({}, params)
      ).toString()

      return fetchWrapper.get(`/api/v1/user/${this.user.id}/subscription?${query}`)
        .then((response) => {
          return response;
        })
    },
    isEnabled(feature: FeatureFlag) {
      return !!this.user?.features?.[feature]
    },
    async createNewOtp() {
      return fetchWrapper.post(`/api/v1/auth/otp/create`)
        .then((response) => {
          return response;
        })
    },
    async confirmNewOtp(otp: string) {
      return fetchWrapper.post(`/api/v1/auth/otp/confirm`, { otp })
        .then((response) => {
          return response;
        })
    },
    async removeOtp(password: string) {
      return fetchWrapper.delete(`/api/v1/auth/otp/remove`, { password })
        .then((response) => {
          return response;
        })
    },
    async deleteAccount(password: string) {
      return fetchWrapper.delete(`/api/v1/auth/delete-account`, { currentPassword: password })
        .then((response) => {
          return response;
        })
    },
    setReturnUrl(url: string) {
      this.returnUrl = url;
      sessionStorage.setItem('returnUrl', url);
    },
    getReturnUrl(clear: boolean = false) {
      const returnUrl = this.returnUrl || sessionStorage.getItem('returnUrl') || undefined;
      if (clear) {
        sessionStorage.removeItem('returnUrl');
        this.returnUrl = '';
      }
      return returnUrl;
    }
  }
});


let polling = setInterval(() => {
  void useAuthStore().getNotfications().then(result => {
    if (!result) {
      clearInterval(polling);
    }
  });
}, 30000);

addEventListener('beforeunload', () => {
  savePreferencesToServer.flush();
});
