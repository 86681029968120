<template>
  <v-alert
    v-if=showAlert
    icon="mdi-alert-box-outline"
    class="mb-6"
    density="compact"
    prominent
  >
    <div v-if="hasDuplicateTransaction">
      {{ errorMessage('duplicate') }}
    </div>
    <div v-else-if="hasDuplicateCounterparty">
      {{ errorMessage('duplicateCounterparty') }}
    </div>
  </v-alert>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  serverSideValidationResult: {
    required: false
  },
  formType: {
    type: String,
    required: true
  }
})

const { t } = useI18n();

const errorMessage = (rule: string) => {
  const messages: Record<string, any> = {
    duplicate: {
      investment: t('validation.transaction.duplicate.investment'),
      account: t('validation.transaction.duplicate.account')
    },
    duplicateCounterparty: {
      common: t('validation.transaction.duplicateCounterparty.common'),
    }
  }

  const options = messages[rule] as any;

  return options[props.formType] || options.common;
}

const showAlert = computed(() => {
  return props.serverSideValidationResult !== null && !props.serverSideValidationResult.isValid
})

const hasDuplicateTransaction = computed(() => {
  return props.serverSideValidationResult?.invalidRules?.duplicate;
})

const hasDuplicateCounterparty = computed(() => {
  return props.serverSideValidationResult?.invalidRules?.duplicateCounterparty;
})
</script>