import FullLayout from '@/layouts/full/FullLayout.vue';
import StarterPage from '@/views/StarterPage.vue';

const MainRoutes = {
  path: '/',
  meta: {
    requiresAuth: true
  },
  component: FullLayout,
  children: [
    {
      name: 'dashboard',
      path: '',
      alias: ':portfolioId(\\d+)?',
      component: StarterPage,
      meta: {
        requiresAuth: true,
        requiresTier: false,
        title: 'Dashboard'
      }
    },
    {
      name: 'advisor',
      path: 'advisor',
      component: () => import('@/views/AdvisorPage.vue'),
      meta: {
        requiresAuth: true,
        requiresTier: false,
        title: 'Advisor'
      }
    }
  ]
};

export default MainRoutes;
