<script setup lang="ts">
import { RouterView, useRoute } from 'vue-router';
import HorizontalHeader from './horizontal-header/HorizontalHeader.vue';
import HorizontalSidebar from './horizontal-sidebar/HorizontalSidebar.vue';
import { useAppStore } from '@/stores/app';
import LayoutNotice from '../components/LayoutNotice.vue';
import { computed } from 'vue';
const appStore = useAppStore();

const route = useRoute();

const isFlat = computed(() => {
  return route.meta.isFlat === true;
});
</script>

<template>
  <v-locale-provider>
    <v-app
      :theme="appStore.theme"
      class="Roboto horizontalLayout"
      :class="[
        appStore.hideBalances ? 'hide-balance' : '',
        appStore.theme,
      ]"
    >
      <HorizontalHeader />
      <v-main class="ma-0">
        <div id="sub-menu">
          <HorizontalSidebar />
        </div>
        <v-container fluid class="page-wrapper" :class="{'pa-0': isFlat}">
          <LayoutNotice />
          <div :class="{'pt-5': !isFlat, 'pb-5': !isFlat, maxWidth: !isFlat}">
            <RouterView />
          </div>
        </v-container>
      </v-main>
    </v-app>
  </v-locale-provider>
</template>