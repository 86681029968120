<script setup lang="ts">
import { computed, ref } from 'vue';
import { CsvFileFields, CsvImportType } from '~/enums';
import { useI18n } from 'vue-i18n';
import { watch } from 'vue';

const emit = defineEmits(['update:modelValue', 'update:isValid']);
const { t } = useI18n();

const props = defineProps({
  isValid: {
    type: Boolean,
    required: true
  },
  modelValue: {
    type: Object,
    required: false
  },
  fileUploadResult: {
    type: Object,
    required: true
  }
})

const isMappingValid = ref(false);
const importType = computed(() => props.fileUploadResult?.importType);

const requiredFields = computed(() => {
  if (CsvImportType.Transactions === importType.value) {
    return [
      CsvFileFields.Date,
      CsvFileFields.TransactionType,
      CsvFileFields.InstrumentCode,
      CsvFileFields.Quantity,
      CsvFileFields.Price
    ]
  } else {
    return [
      CsvFileFields.Date,
      CsvFileFields.Quantity
    ]
  }
})

const suggestedFields = computed(() => {
  if (CsvImportType.Transactions === importType.value) {
    return [
    CsvFileFields.Fee
    ]
  } else {
    return [
      CsvFileFields.Counterparty,
      CsvFileFields.Description,
      CsvFileFields.UniqueId,
      CsvFileFields.Currency
    ]
  }
})

const allowedFields = computed(() => {
  const common = [
    CsvFileFields.Date,
    CsvFileFields.TransactionType,
    CsvFileFields.Quantity,
    CsvFileFields.Fee,
    CsvFileFields.Currency,
    CsvFileFields.ExchangeRate,
    CsvFileFields.Unknown,
    CsvFileFields.Ignore,
    CsvFileFields.UniqueId,
    CsvFileFields.NetAmount,
    CsvFileFields.GrossAmount,
    CsvFileFields.Description,
    CsvFileFields.InstrumentName,
    CsvFileFields.IsinCode,
    CsvFileFields.Tax,
    CsvFileFields.ValueDate
  ]
  if (CsvImportType.Transactions === importType.value) {
    return [
      ...common,
      CsvFileFields.Price,
      CsvFileFields.InstrumentCode,
    ]
  } else {
    return [
      ...common,
      CsvFileFields.Counterparty
    ]
  }
})

const translations = computed<Record<string, any>>(() => {
  return {
    fields: {
      [CsvFileFields.Date]: t('csv.date'),
      [CsvFileFields.TransactionType]: t('csv.transaction_type'),
      [CsvFileFields.InstrumentCode]: t('csv.instrument_code'),
      [CsvFileFields.InstrumentName]: t('csv.instrument_name'),
      [CsvFileFields.Quantity]: importType.value === CsvImportType.Transactions ? t('csv.quantity') : t('csv.amount'),
      [CsvFileFields.Price]: t('csv.price'),
      [CsvFileFields.Fee]: t('csv.fee'),
      [CsvFileFields.Currency]: t('csv.currency'),
      [CsvFileFields.ExchangeRate]: t('csv.exchange_rate'),
      [CsvFileFields.Unknown]: t('csv.unknown'),
      [CsvFileFields.Ignore]: t('csv.ignore'),
      [CsvFileFields.UniqueId]: t('csv.unique_id'),
      [CsvFileFields.Tax]: t('csv.tax'),
      [CsvFileFields.IsinCode]: t('csv.isin_code'),
      [CsvFileFields.ValueDate]: t('csv.value_date'),
      [CsvFileFields.NetAmount]: t('csv.net_amount'),
      [CsvFileFields.GrossAmount]: t('csv.gross_amount'),
      [CsvFileFields.Description]: t('csv.description'),
    }
  }
})

const fieldsConfig = computed(() => {
  return Object.values(CsvFileFields).map((field: CsvFileFields) => {
    return {
      key: field,
      title: t(`csv.${field}`),
      isSelected: props.fileUploadResult?.headers.some((item: any) => item.field === field),
      required: requiredFields.value.includes(field),
      suggested: suggestedFields.value.includes(field)
    }
  })
})

const itemColor = (item: any) => {
  if (suggestedFields.value.includes(item.field)) {
    return 'blue';
  } else if (requiredFields.value.includes(item.field)) {
    return 'green';
  } else if(item.field && ![CsvFileFields.Unknown, CsvFileFields.Ignore].includes(item.field)) {
    return 'orange';
  }
}

const fieldOptions = computed(() => {
  return allowedFields.value.map((field: CsvFileFields) => {
    return {
      title: translations.value.fields[field] ?? field,
      value: field
    }
  })
})

const isValid = computed (() => {
  return isMappingValid.value === true && fieldsConfig.value.every((item: any) => item.isSelected || !item.required);
});

watch(() => isValid, (isValid) => {
  emit('update:isValid', isValid.value);
}, { deep: true, immediate: true });

const isSelected = (value: string) => {
  return value !== null && value !== undefined && value !== '' && value !== CsvFileFields.Unknown;
}
</script>

<template>
  <v-row>
    <v-col cols="12" lg="12">
      <div class="py-6">
        <p>Vali vähemalt igale nõutud väljale CSV failist vastav tulp.</p>
        <div class="mt-2"><strong>Nõutud väljad: </strong>
          <div class="d-flex justify-start ga-2 mt-2">
            <v-chip 
              v-for="(item, i) in fieldsConfig.filter(f => f.required)"
              :key="i"
              :color="item.isSelected ? 'green' : 'error'"
              variant="tonal"
            >{{ item.title }}</v-chip>
          </div>
        </div>
        <div class="mt-4" v-if="suggestedFields.length"><strong>Soovituslikud väljad: </strong>
          <div class="d-flex justify-start ga-2 mt-2">
            <v-chip 
              v-for="(item, i) in fieldsConfig.filter(f => f.suggested)"
              :key="i"
              :color="item.isSelected ? 'blue' : 'error'"
              variant="tonal"
            >{{ item.title }}</v-chip>
          </div>
        </div>
      </div>
      <div>
        <v-form class="mt-3" ref="mappingForm" validate-on="input" v-model="isMappingValid">
          <v-table style="border: 1px solid #eee;">
            <thead>
              <tr class="bg-primary">
                <th>
                  CSV faili tulp
                </th>
                <th style="word-break:keep-all;"></th>
                <th style="width:50%;" class="text-left">
                  Vastab väljale
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in fileUploadResult.headers" :key="i">
                <td class="text-left">
                  <v-chip :color="itemColor(item)">{{ item.header }}</v-chip>
                </td>
                <td class="text-left">
                </td>
                <td class="text-left">
                  <v-select
                    density="compact"
                    v-model="item.field"
                    :items="fieldOptions"
                    variant="outlined"
                    hide-details
                    :rules="[isSelected]"
                  />
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-form>
      </div>
    </v-col>
  </v-row>
</template>