<script setup lang="ts">
import { ref } from 'vue';
import { fieldDesign, accountProviders, rules } from '@/const';
import { usePortfolioStore } from '@/stores/portfolioStore';
import { useAccountStore } from '@/stores/accountStore';
import { computed } from 'vue';
import type { PortfolioDtoType } from '~/portfolio.schema';
import { useRouter } from 'vue-router';
import FormContainer from '@/components/shared/FormContainer.vue';
import { useAppStore } from '@/stores/app';
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';
import Logo from '@/components/logos/Logo.vue';
import ButtonToggle from '@/components/inputs/ButtonToggle.vue';
import CustomGroupSelectFieldVue from '@/components/inputs/CustomGroupSelectField.vue';
import type { Currency } from '~/enums';
import CurrencyField from '@/components/inputs/CurrencyField.vue';
import TabsLayout from '@/components/layout/TabsLayout.vue';
import ConfirmDialog from '@/components/shared/ConfirmDialog.vue';

const portfolioStore = usePortfolioStore();
const accountStore = useAccountStore();
const appStore = useAppStore();
const router = useRouter();
const { t } = useI18n();

const emit = defineEmits(['created']);
const props = defineProps(['action', 'location'])

const confirmDialog = ref(null);
const formRef = ref(null);
const isLoading = ref(false);
const isFormLoading = ref(false);
const isUpdate = computed(() => {
  return props.action === 'update';
});

const portfolio = computed(() => portfolioStore.selectedPortfolio as PortfolioDtoType);

const translations = computed(() => {
  return {
    title: isUpdate.value ? t('account_page.form.update_title') : t('account_page.form.create_title')
  }
})

const form = ref({
  name: undefined as any,
  currencyCode: portfolio.value.currencyCode,
  portfolioId: portfolio.value.id,
  provider: undefined as any,
  isInPortfolio: true,
  trackingType: 'none',
  customGroups: [],
  isArchived: false,
})

const formFinal = computed(() => {
  return {
    ...form.value,
    providerId: form.value.provider?.id
  }
})

watch(portfolio, (portfolio) => {
  form.value.currencyCode = portfolio.currencyCode;
  form.value.portfolioId = portfolio.id;
}, { immediate: true })

watch(() => accountStore.account, (account) => {
  if (!account || !account.id || !isUpdate.value) {
    return;
  }
  form.value.portfolioId = account.portfolioId;
  form.value.name = account.name;
  form.value.currencyCode = account.currencyCode;
  form.value.provider = account.providerId
  form.value.isInPortfolio = account.isInPortfolio;
  form.value.trackingType = account.trackingType; 
  form.value.isArchived = account.isArchived;
}, { immediate: true });

const onSubmit = async () => {
  isLoading.value = true;

  try {
    if (isUpdate.value) {
      await accountStore.updateCashAccount(accountStore.account.id, formFinal.value);
      appStore.showNotice(t('account_page.messages.updated'), 'success');
    } else {
      const account = await accountStore.createCashAccount(formFinal.value);
      appStore.showNotice(t('account_page.messages.created'), 'success');
      if (props.location === 'modal') {
        emit('created', account)
      } else {
        router.push({ name: 'account', params: { accountId: account.id }});
      }
    }
    await portfolioStore.getCategories();
  } finally {
    isLoading.value = false;
  }
}

const triggerDelete = async () => {
  if (await confirmDialog.value?.open(t('label.confirm'), t('account_page.form.delete_confirm'))) {
    onDelete();
  }
}

const onDelete = async () => {
  isFormLoading.value = true;

  try {
    await accountStore.deleteCashAccount(accountStore.account.id);
    appStore.showNotice(t('account_page.messages.deleted'), 'success');
    router.push({ name: 'portfolio', params: { portfolioId: portfolio.value.id }});
  } finally {
    isFormLoading.value = false;
  }
}

const getProvider = (id: any) => {
  return accountProviders.value.find((provider: any) => provider.id === id) || accountProviders.value[0];
}

const onExport = () => {
  document.location = '/api/v1/account/' + accountStore.account.id + '/export';
}

const tab = ref('settings');

const tabs = computed(() => {
  return [
    { label: t('portfolio_page.form.settings'), value: 'settings', icon: 'mdi-cog', disabled: false },
    { label: t('investment_page.actions'), value: 'actions', icon: 'mdi-button-pointer', disabled: !isUpdate.value },
  ]
})
</script>

<template>
  <FormContainer
    icon="mdi-bank-outline"
    color="panel-heading-bg"
    ref="formRef"
    :title="translations.title"
    :mode="isUpdate ? 'update' : 'create'"
    @submit="onSubmit"
    @delete="onDelete"
    :is-form-loading="isFormLoading"
    :is-button-loading="isLoading"
    :confirm-text="t('account_page.form.delete_confirm')"
    :help-text="t('account_page.form.help_text')"
    :flavour="'tabs'"
    hide-delete
  >
    <TabsLayout :tabs="tabs" v-model="tab">
      <v-tabs-window-item value="settings">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-bind="fieldDesign"
              type="text"
              v-model="form.name"
              :label="t('label.cash_account_name')"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              v-bind="(fieldDesign as any)"
              v-model="form.provider"
              :items="accountProviders"
              :label="t('label.account_provider')"
              item-title="title"
              item-value="id"
              return-object
              id="account-provider"
              :rules="[rules.required]"
            >
              <template v-slot:prepend-inner>
                <Logo avatar :broker="form.provider?.id || form.provider" :name="getProvider(form.provider?.id || form.provider)?.shortName ?? getProvider(form.provider?.id || form.provider)?.title" />
              </template>
              <template v-slot:item="{ props, item }">
                <v-list-item v-bind="props" :title="''">
                  <v-list-item-title>
                    {{ item.title }}
                  </v-list-item-title>
                  <template v-slot:prepend>
                    <Logo avatar :broker="item.raw.id" :name="item.raw.shortName ?? item.raw.title" />
                  </template>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12">
            <CurrencyField
              :label="t('label.base_currency')"
              v-model="form.currencyCode as Currency"
              :disabled="isUpdate"
              :helpText="$t('account_page.form.currency_help')"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="12" lg="12">
            <CustomGroupSelectFieldVue
              v-bind="fieldDesign"
              :label="t('label.custom_groups')"
              v-model="form.customGroups"
              :investmentId="isUpdate ? `C${accountStore.accountId}` : null"
            />
          </v-col>
          <v-col cols="12">
            <div class="mb-2">
              <strong>
                {{ t('label.accoubt_tracking_type') }}
              </strong>
            </div>
            <ButtonToggle
              v-model="form.trackingType"
              density="comfortable"
              :options="[{
                title: t('account_page.tracking_type.none'),
                value: 'none'
              }, {
                title: t('account_page.tracking_type.balance'),
                value: 'balance'
              }, {
                title: t('account_page.tracking_type.full'),
                value: 'full'
              }]"
              full-on-mobile
            />
            <p class="mt-2 text-caption">
              {{ t(`account_page.form.${form.trackingType}_help`) }}
            </p>
          </v-col>
          <v-col cols="12" v-if="form.trackingType !== 'none'">
            <v-checkbox
              v-bind="fieldDesign"
              v-model="form.isInPortfolio"
              density="compact"
            >
              <template v-slot:label>
                <div class="d-flex align-center ga-1">
                  {{ t('account_page.form.not_in_portfolio') }}
                  <HoverHelpIcon size="18" slot="append" :helpText="$t('account_page.form.not_in_portfolio_help')" />
                </div>
              </template>
            </v-checkbox>
          </v-col>
          <v-col cols="12">
            <v-checkbox
              v-bind="fieldDesign"
              v-model="form.isArchived"
              :label="t('label.archive')"
              density="compact"
            />
          </v-col>
        </v-row>
      </v-tabs-window-item>
      <v-tabs-window-item value="actions">
        <v-row>
          <v-col cols="12">
            <div class="d-flex flex-column ga-2" style="max-width:300px;">
              <v-btn
                v-if="isUpdate"
                color="blue"
                variant="outlined"
                @click="onExport"
                :loading="isFormLoading"
                :disabled="isFormLoading"
              >
                {{ t('label.export') }}
              </v-btn>
              <v-btn
                variant="tonal"
                color="error"
                :loading="isFormLoading"
                @click="triggerDelete"
              >
                {{ $t('label.delete') }}
                <v-icon class="ml-2" right>mdi-delete</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-tabs-window-item>
    </TabsLayout>
  </FormContainer>
  <ConfirmDialog ref="confirmDialog" />
</template>
