<template>
  <template v-if="account.link?.id">
    <template v-if="account.link.unconfirmedTransactionsCount">
      <v-btn
        prepend-icon="mdi-alert"
        variant="flat"
        color="orange"
        @click="confirmTransactions"
      >
      {{ account.link.unconfirmedTransactionsCount }} {{ $t('account_page.n_unconfirmed_transactions') }} 
      </v-btn>
    </template>
    <template v-if="account.link.status === 'expired'">
      <v-btn prepend-icon="mdi-alert" color="error" variant="tonal" :to="{ name: 'import-links' }">
        {{ $t('account_page.connection_expired') }}
        <HoverHelpIcon class="ml-1" color="error">
          {{ $t('account_page.connection_expired_help') }}
        </HoverHelpIcon>
      </v-btn>
    </template>
    <template v-else-if="account.link.status === 'error'">
      <v-btn prepend-icon="mdi-alert" color="error" variant="tonal" :to="{ name: 'import-links' }">
        {{ $t('account_page.connection_error') }}
        <HoverHelpIcon class="ml-1" color="error">
          {{ $t('account_page.connection_error_help') }}
        </HoverHelpIcon>
      </v-btn>
    </template>
    <template v-else-if="!account.link.unconfirmedTransactionsCount && version === 'minimal'">
      <div class="d-flex align-center justify-end">
        <v-icon size="32" color="success">mdi-check</v-icon>
        <v-btn :loading="manualSyncLoading" icon="mdi-sync" @click="sync" color="green" variant="text"></v-btn>
      </div>
    </template>
    <template v-if="version !== 'minimal'">
      <v-btn prepend-icon="mdi-sync" @click="unlink" color="error" variant="tonal">
        {{ $t('account_page.unlink') }}
      </v-btn>
    </template>
  </template>
  <template v-else-if="canBeLinked">
    <v-btn prepend-icon="mdi-sync" @click="link" color="green" variant="tonal">
      {{ $t('account_page.add_link') }}
    </v-btn>
  </template>
  <component :is="createConnectionOpts.component" :visible="showCreateAccountLink" :account="account" @close="onCloseAccountLink" />
  <UnlinkDialog :account="account" v-model:model-visible="showUnlinkDialog" @unlinked="onUnlinked" />
  <LinkingErrorDialog v-model:model-visible="showErrorLinkDialog" />
</template>

<script setup lang="ts">
import type { PropType } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import type { AccountDtoType } from '~/cash-account.schema';
import CreateAccountConnection from '@/components/CreateAccountConnection.vue';
import CreateIBConnection from '@/components/CreateIBConnection.vue';
import { computed, onMounted, ref } from 'vue';
import { usePortfolioStore } from '@/stores/portfolioStore';
import { useAuthStore } from '@/stores/authStore';
import UnlinkDialog from './UnlinkDialog.vue';
import { useAccountStore } from '@/stores/accountStore';
import { fetchWrapper } from '@/utils/helpers/fetch-wrapper';
import LinkingErrorDialog from './LinkingErrorDialog.vue';
import { useAccountLink } from '@/utils/account.utils';

const props = defineProps({
  account: {
    type: Object as PropType<AccountDtoType>,
    required: true,
  },
  version: {
    type: String,
    default: 'minimal',
  },
});

const router = useRouter();
const route = useRoute();
const portfolioStore = usePortfolioStore();
const accountStore = useAccountStore();
const authStore = useAuthStore();

const manualSyncLoading = ref(false);
const showCreateAccountLink = ref(false);
const showUnlinkDialog = ref(false);
const showErrorLinkDialog = ref(false);

const { canBeLinked } = useAccountLink(computed(() => props.account));

const refresh = async () => {
  await Promise.all([
    portfolioStore.getAllAccounts(portfolioStore.selectedPortfolioId),
    authStore.getMe(),
    accountStore.refresh()
  ]);
}

const link = () => {
  showCreateAccountLink.value = true;
}

const unlink = async () => {
  showUnlinkDialog.value = true; 
}

const onUnlinked = () => {
  showUnlinkDialog.value = false;
  refresh();
}

const sync = async () => {
  try {
    manualSyncLoading.value = true;
    await fetchWrapper.put(`/api/v1/import/sync/${props.account?.link?.id}`);
    await refresh();
  } catch (error) {
    console.error(error);
  } finally {
    manualSyncLoading.value = false;
  }
}

const confirmTransactions = () => {
  router.push({
    name: 'import',
    query: {
      accountId: props.account.id,
      importType: 'confirm'
    },
    params: {
      portfolioId: props.account.portfolioId
    }
  })
}

const onCloseAccountLink = async () => {
  showCreateAccountLink.value = false;
  refresh();
}

const createConnectionOpts = computed(() => {
  const service = props.account?.link?.service;

  return {
    component: service === 'ib' ? CreateIBConnection : CreateAccountConnection,
  }
})

onMounted(async () => {
  if (route.query.accountId && props.account.id === parseInt(route.query.accountId as any)) {
    if (route.query.accountId && route.query.success === 'true') {
      link();
    } else if (route.query.accountId && route.query.success === 'false') {
      showErrorLinkDialog.value = true;
    }
  }
})

defineExpose({ link });
</script>