<template>
  <Dialog v-model:model-visible="visible"  :title="$t('account_page.link.ib.title')">
    <p class="text-body-1" v-html="$t('account_page.link.ib.description')" />
    <p class="mt-2 text-caption">
      {{ $t('account_page.link.ib.description2') }}
    </p>
    <v-form>
      <v-row dense class="py-4">
        <v-col cols="12">
          <v-text-field
            v-model="form.flexQueryId"
            label="FlexQuery ID"
            v-bind="(fieldDesign as any)"
            dense
            required
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="form.apiKey"
            label="Token"
            v-bind="(fieldDesign as any)"
            outlined
            dense
            required
          />
          <p class="mt-3 text-caption">
            {{ $t('account_page.link.ib.token_description') }}
          </p>
        </v-col>
      </v-row>
      <v-divider class="mb-5"></v-divider>
      <v-btn
        :loading="isLoading"
        :disabled="isLoading"
        variant="flat"
        color="green"
        @click="addConnection"
      >
        {{ $t('account_page.link.ib.connect') }}
      </v-btn>
    </v-form>
  </Dialog>
</template>

<script setup lang="ts">
import { fieldDesign } from '@/const';
import { useAppStore } from '@/stores/app';
import { fetchWrapper } from '@/utils/helpers/fetch-wrapper';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const emit = defineEmits(['close']);
const { t } = useI18n();

const props = defineProps({
  visible: Boolean,
  account: {
    type: Object,
    required: false
  }
});

const appStore = useAppStore();

const visible = computed({
  get: () => props.visible,
  set: (value) => {
    emit('close');
  }
});

const isLoading = ref(false);
const form = ref({
  flexQueryId: '',
  apiKey: ''
});

const addConnection = async () => {
  isLoading.value = true;
  try {
    await fetchWrapper.put(`/api/v1/import/start/${props.account.providerId}?portfolioId=${props.account.portfolioId}&holdingId=${props.account.id}&service=ib`, {
      settings: {
        ...form.value
      }
    });
    appStore.showNotice(t('account_page.link.ib.success'), 'success');
    emit('close');
  } catch (error) {
    isLoading.value = false;
  } finally {
    isLoading.value = false;
  }
}
</script>