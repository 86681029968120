<template>
  Loading...
</template>

<script setup lang="ts">
import { onMounted } from 'vue';

onMounted(() => {
  document.location = '/api/v1/auth/oauth2/authorize/device'
});
</script>