<script setup lang="ts">
import { rules, fieldDesign } from '@/const';
import { ref, computed } from 'vue';
import { usePortfolioStore } from '@/stores/portfolioStore';
import * as I from '~/investment.schema';
import { watch } from 'vue';
import * as P from '~/portfolio.schema';
import { useInvestmentStore } from '@/stores/investmentStore';
import { useRouter } from 'vue-router';
import FormContainer from '@/components/shared/FormContainer.vue';
import { useInstrumentConfig, useTranslationHelper } from '@/instrument.helper';
import { useI18n } from "vue-i18n";
import { useTransactionForm } from '@/utils/transaction.utils';
import type { CreateCashAccountTransactionDtoType } from '~/cash-account.schema';
import AccountSearchField from "@/components/inputs/AccountSearchField.vue";
import { useAppStore } from '@/stores/app';
import IncomeForm from "@/components/partial/IncomeForm.vue";

defineExpose({ update });
const emit = defineEmits(['update:modelValue', 'update:isValid']);
const props = defineProps(['action', 'investment', 'modelValue', 'isValid'])
const { t, te } = useI18n();

const app = useAppStore();
const investmentStore = useInvestmentStore();
const portfolioStore = usePortfolioStore();
const router = useRouter();

const formRef = ref(null);
const isDeleteLoading = ref(false);
const isLoading = ref(false);
const isUpdate = computed(() => {
  return props.action === 'update';
});

const translations = computed(() => {
  return {
    title: isUpdate.value ? t('investment_page.income.update_title') : t('investment_page.income.create_title'),
    hints: {
      dateTransactionAt: t('investment_page.income.date_help'),
      quantity: t('investment_page.income.quantity_help'),
      grossAmount: t('investment_page.income.gross_quantity_help'),
      relatedHoldingId: t('investment_page.income.related_holding_help')
    }
  }
})

const isValid = computed({
  get() {
    return props.isValid;
  },
  set(value) {
    emit('update:isValid', value);
  }
})

const investment = computed(() => props.investment ?? investmentStore.investment as I.InvestmentDtoType)
const { iConf } = useInstrumentConfig(investment)
const portfolio = computed(() => portfolioStore.portfolio as P.PortfolioDtoType)
const income = computed(() => isUpdate.value ? investmentStore.income as CreateCashAccountTransactionDtoType : undefined)

const { form, resetFormForNew } = useTransactionForm(investment, income, ref(true));

function update() {
  beforeApiCall();
  emit('update:modelValue', form);
}

watch (() => props.modelValue, (modelValue) => {
  if (modelValue) {
    Object.assign(form, modelValue);
  }
}, { immediate: true })

const { getBroadKey } = useTranslationHelper(investment, computed(() => form.transactionType));

const transactionTypeOptions = computed(() => {
  return iConf.value?.allowedIncomeTypes.map((type) => {
    const broadKey = getBroadKey(`transactionType.${type}.title`)
    return {
      title: te(broadKey) ? t(broadKey) : t(`transactionType.${type}.title`),
      value: type
    }
  })
})

const finalForm = computed<CreateCashAccountTransactionDtoType>(() => {
  return {
    portfolioId: portfolio.value?.id,
    date: form.date,
    transactionType: form.transactionType,
    description: form.description,
    account: {
      id: form.account.id,
      currencyCode: form.account.currencyCode,
      quantity: form.account.netAmount ?? form.account.grossAmount,
      netAmount: form.account.netAmount ?? form.account.grossAmount,
      grossAmount: form.account.grossAmount,
      fee: form.account.fee || 0,
      feeCurrencyCode: form.account.feeCurrencyCode,
      tax: form.account.tax || 0,
      taxCurrencyCode: form.account.taxCurrencyCode,
      price: 1,
    },
    counterparty: {
      id: form.counterparty.id,
      currencyCode: form.account.currencyCode,
      netAmount: form.account.netAmount,
      grossAmount: form.account.netAmount,
      fee: 0,
      feeCurrencyCode: form.counterparty.feeCurrencyCode,
      tax: 0,
      taxCurrencyCode: form.counterparty.taxCurrencyCode,
      price: 1
    }
  }
})

const beforeApiCall = () => {
  isLoading.value = true;
}

const onCreate = async () => {
  return investmentStore.createIncome(investment.value.id, finalForm.value)
}

const onUpdate = async () => {
  return investmentStore.updateIncome(investment.value.id, income.value.id, finalForm.value)
}

const onSubmit = async (addNew: boolean = false) => {
  isLoading.value = true;

  beforeApiCall();

  try {
    if (isUpdate.value) {
      await onUpdate();
      app.showNotice(t('investment_page.income.messages.updated'), 'success');
    } else {
      app.showNotice(t('investment_page.income.messages.created'), 'success');
      await onCreate();
    }
    if (addNew) {
      resetFormForNew();
    } else {
      router.go(-1);
    }
  } finally {
    isLoading.value = false;
  }
}

const onDelete = async () => {
  try {
    isDeleteLoading.value = true;
    await investmentStore.deleteTransaction(investment.value.id, income.value.id as string)
    router.push({ name: 'investment', params: { investmentId: investment.value.id } });
  } finally {
    isDeleteLoading.value = true;
  }
}

const isFormLoading = computed(() => {
  return isLoading.value || investmentStore.income.loading;
})
</script>

<template>
  <FormContainer
    v-model="isValid"
    ref="formRef"
    :title="translations.title"
    :mode="isUpdate ? 'update' : 'create'"
    @submit="onSubmit"
    @delete="onDelete"
    :is-form-loading="isFormLoading"
    :is-button-loading="isLoading"
    :action="action"
    :confirm-text="t('investment_page.income.confirm_delete')"
    color="panel-heading-bg"
    show-add-new
  >
    <v-row>
      <v-col cols="12" lg="6">
        <DateField
          v-model="form.date"
          :label="t('label.date')"
        />
      </v-col>
      <v-col cols="12" lg="6">
        <v-select
          v-bind="(fieldDesign as any)"
          :rules="[rules.required]"
          :items="transactionTypeOptions"
          :label="t('label.transaction_type')"
          v-model="form.transactionType"
        />
      </v-col>
      <IncomeForm :form="form" />
      <v-col cols="12" lg="12" v-show="action !== 'import'">
        <AccountSearchField
          :fieldDesign="fieldDesign"
          v-model="form.counterparty.id"
          :label="t('label.account')"
          :currency-code="form.account.currencyCode"
          :hint="t('help.account')"
        />
      </v-col>
    </v-row>

    <template v-slot:before-actions>
      
    </template>
  </FormContainer>
</template>
