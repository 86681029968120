<script setup lang="ts">
import { fieldDesign, rules, nr } from '@/const';
import { ref, computed, watch } from 'vue';
import { TransactionType } from '~/enums';
import { useAccountStore } from '@/stores/accountStore';
import { usePortfolioStore } from '@/stores/portfolioStore';
import * as S from '~/cash-account.schema';
import { useRouter } from 'vue-router';
import FormContainer from '@/components/shared/FormContainer.vue';
import * as P from '~/portfolio.schema';
import TransactionSummary from '@/components/shared/TransactionSummary.vue';
import { useI18n } from 'vue-i18n';
import AmountField from '@/components/inputs/AmountField.vue';
import FeeField from '@/components/inputs/FeeField.vue';
import Decimal from 'decimal.js';
import { useTransactionForm } from '@/utils/transaction.utils';
import AccountSearchField from "@/components/inputs/AccountSearchField.vue";
import { useAppStore } from '@/stores/app';
import TransactionFormAlert from '@/components/inputs/TransactionFormAlert.vue';

const emit = defineEmits(['update:modelValue', 'update:isValid']);
const props = defineProps(['action', 'account', 'modelValue', 'isValid', 'showAccountSelector'])

const app = useAppStore();
const accountStore = useAccountStore();
const portfolioStore = usePortfolioStore();
const router = useRouter();
const { t } = useI18n();

const selectedAccount = ref(null);
const formRef = ref(null);
const isLoading = ref(false);
const isUpdate = computed(() => props.action === 'update');

const isValid = computed({
  get() {
    return props.isValid;
  },
  set(value) {
    emit('update:isValid', value);
  }
})

const account = computed(() => props.account || selectedAccount.value || accountStore.account as S.AccountDtoType)
const portfolio = computed(() => portfolioStore.activePortfolio as P.PortfolioDtoType)
const cashAccounts = computed(() => portfolioStore.portfolioAccounts.item)
const transaction = computed(() => props.modelValue || (isUpdate.value ? accountStore.transaction as S.CreateCashAccountTransactionDtoType : undefined))
const finalFormRef = ref(undefined);

const {
  form,
  resetFormForNew,
  relatedAccountModel,
  serverSideValidationResult,
  canEditRelatedAccount,
} = useTransactionForm(account, transaction, ref(false), finalFormRef);

const translations = computed<(Record<any, any>)>(() => {
  return {
    title: isUpdate.value ? t('account_page.form.update_transaction') : t('account_page.form.create_transaction'),
    hints: {
      transactionType: {
        [TransactionType.Opening]: t(`transactionType.${TransactionType.Opening}.help`),
        [TransactionType.Transfer]: t(`transactionType.${TransactionType.Transfer}.help`),
        [TransactionType.Interest]: t(`transactionType.${TransactionType.Interest}.help`),
        [TransactionType.Deposit]: t(`transactionType.${TransactionType.Deposit}.help`),
        [TransactionType.Withdraw]: t(`transactionType.${TransactionType.Withdraw}.help`)
      }
    }
  }
})

const validate = async () => {
  // @ts-expect-error
  return formRef.value?.validate();
};

const transactionTypeOptions = computed(() => {
  return [{
    value: TransactionType.Opening,
  }, {
    value: TransactionType.Deposit
  }, {
    value: TransactionType.Withdraw
  }, {
    value: TransactionType.Conversion
  }, {
    value: TransactionType.Interest
  }, {
    value: TransactionType.OtherIncome,
  }, {
    value: TransactionType.InterestPaid
  }, {
    value: TransactionType.Fee
  }, {
    value: TransactionType.Tax
  }].map((option) => {
    return {
      ...option,
      title: t(`transactionType.${option.value}.title`)
    }
  })
})

const showRelatedAccount = computed(() => {
  return [
    TransactionType.Withdraw,
    TransactionType.Deposit
  ].includes(form.transactionType as TransactionType);
})

const showFee = computed(() => {
  return [
    TransactionType.Conversion,
  ].includes(form.transactionType as TransactionType)
})

const showTax = computed(() => {
  return [
    TransactionType.Interest
  ].includes(form.transactionType as TransactionType)
})

const showCounterpartyFee = computed(() => {
  if ([TransactionType.Conversion].includes(form.transactionType)) {
    return true;
  }
  if ([TransactionType.Deposit, TransactionType.Withdraw].includes(form.transactionType) && form.counterparty.id) {
    return true;
  }
})

const showCounterparty = computed(() => {
  if ([TransactionType.Conversion].includes(form.transactionType)) {
    return true;
  }
  return false;
})

const finalForm = computed<S.CreateCashAccountTransactionDtoType>(() => {
  const hasCounterparty = showRelatedAccount.value || form.transactionType === TransactionType.Conversion;
  const counterpartyId = form.transactionType === TransactionType.Conversion
    ? form.account.id
    : (hasCounterparty ? form.counterparty.id : undefined);

  let accountGrossAmount = new Decimal(nr(form.account.grossAmount));
  const sign = [TransactionType.Deposit, TransactionType.Interest].includes(form.transactionType) ? -1 : 1;
  const netAmount = accountGrossAmount
    .add(showFee.value ? nr(form.account.fee) * sign : 0)
    .add(showTax.value ? nr(form.account.tax) * sign : 0)
    .toNumber();
  const counterpartyGrossAmount = new Decimal(nr(form.counterparty.grossAmount));
  const counterpartyNetAmount = counterpartyGrossAmount
    .add(showCounterpartyFee.value ? nr(form.counterparty.fee * -sign) : 0)
    .toNumber();


  return {
    portfolioId: portfolio.value?.id,
    date: form.date,
    transactionType: form.transactionType,
    description: form.description,
    account: {
      id: form.account.id,
      currencyCode: form.account.currencyCode,
      quantity: accountGrossAmount.toNumber(),
      grossAmount: accountGrossAmount.toNumber(),
      netAmount,
      fee: showFee.value ? (form.account.fee || 0) : 0,
      feeCurrencyCode: form.account.feeCurrencyCode,
      tax: showTax.value ? (form.account.tax || 0) : 0,
      taxCurrencyCode: form.account.taxCurrencyCode,
      price: 1,
    },
    counterparty: {
      id: counterpartyId,
      currencyCode: form.counterparty.currencyCode,
      netAmount: counterpartyNetAmount,
      grossAmount: counterpartyGrossAmount.toNumber(),
      fee: showCounterpartyFee ? (form.counterparty.fee || 0) : 0,
      feeCurrencyCode: form.counterparty.feeCurrencyCode,
      tax: 0,
      taxCurrencyCode: form.counterparty.taxCurrencyCode,
      price: 1
    }
  }
})

watch(() => finalForm.value, (value) => {
  finalFormRef.value = value as any;
  if (!props.modelValue) {
    return;
  }
  props.modelValue.description = value.description;
  props.modelValue.date = value.date;
  props.modelValue.transactionType = value.transactionType;
  props.modelValue.account.quantity = value.account.quantity;
  props.modelValue.account.grossAmount = value.account.grossAmount;
  props.modelValue.account.netAmount = value.account.netAmount;
  props.modelValue.account.fee = value.account.fee;
  props.modelValue.account.tax = value.account.tax;
  props.modelValue.counterparty.id = value.counterparty.id;
  props.modelValue.counterparty.grossAmount = value.counterparty.grossAmount;
  props.modelValue.counterparty.netAmount = value.counterparty.netAmount;
  props.modelValue.counterparty.fee = value.counterparty.fee;
  props.modelValue.counterparty.tax = value.counterparty.tax;
  props.modelValue.counterparty.currencyCode = value.counterparty.currencyCode;
  props.modelValue.account.currencyCode = value.account.currencyCode;
  props.modelValue.account.feeCurrencyCode = value.account.feeCurrencyCode;
  props.modelValue.account.taxCurrencyCode = value.account.taxCurrencyCode;
  props.modelValue.counterparty.feeCurrencyCode = value.counterparty.feeCurrencyCode;
  props.modelValue.counterparty.taxCurrencyCode = value.counterparty.taxCurrencyCode;
}, {})

const arrow = computed(() => {
  return form.transactionType === TransactionType.Conversion || form.transactionType === TransactionType.Withdraw
    ? '→'
    : '←';
})

function update() {
  beforeApiCall();
  emit('update:modelValue', form);
}

watch(() => form.account.grossAmount, (value) => {
  if (showRelatedAccount.value) {
    form.counterparty.grossAmount = value;
  }
})

watch (relatedAccountModel, (relatedAccount) => {
  if (!relatedAccount) {
    return;
  }
  if (showRelatedAccount.value) {
    form.counterparty.currencyCode = form.account.currencyCode
    form.counterparty.feeCurrencyCode = form.account.currencyCode;
    form.counterparty.grossAmount = form.account.grossAmount;
  }
  // Is this needed?
}, { immediate: true })

const exchangeRatePair = computed(() => {
  const portflioCurrencyCode = portfolio.value?.currencyCode;
  const relatedAccountCurrencyCode = relatedAccountModel.value?.currencyCode;
  const currencyCodes = [portfolio.value?.currencyCode, account.value?.currencyCode, relatedAccountCurrencyCode];
  const currencyCode = currencyCodes.find((code) => code !== portflioCurrencyCode && code !== undefined) ?? account.value?.currencyCode;
  return [portflioCurrencyCode, currencyCode]
})

const beforeApiCall = () => {
  isLoading.value = true;
}

const submit = async (addNew: boolean = false) => {
  beforeApiCall();

  await accountStore.createCashAccountTransaction(account.value.id, finalForm.value)
    .then(() => {
      app.showNotice(t('account_page.messages.transaction_created'), 'success');
      if (addNew) {
        resetFormForNew()
      } else {
        router.push({ name: 'account', params: { accountId: account.value.id } });
      }
    })
    .catch(() => {})
    .finally(() => {
      isLoading.value = false;
    })
}

const onUpdate = async () => {
  beforeApiCall();

  await accountStore.updateCashAccountTransaction(account.value.id, transaction.value.id, finalForm.value)
    .then(() => {
      router.go(-1);
    })
    .catch(() => {
      isLoading.value = false;
    })
}

const onDelete = async () => {
  await accountStore.deleteCashAccountTransaction(account.value.id, transaction.value.id as string)
    .then(() => {
      router.go(-1);
    })
    .catch(() => {
      isLoading.value = false;
    })
}

const isFormLoading = computed(() => {
  return accountStore.transaction.loading || accountStore.account.loading;
})

const isConversion = computed(() => {
  return form.transactionType === TransactionType.Conversion;
})

const fxRule = () => {
  return form.account.currencyCode !== form.counterparty.currencyCode || t('rules.currency_mismatch');
}

const counterpartyRules = computed(() => {
  const list = [
    rules.value.required,
  ]
  if (isConversion.value) {
    list.push(fxRule);
  }
  return list;
})

defineExpose({ update, validate });
</script>

<template>
  <form-container
    icon="mdi-bank"
    color="panel-heading-bg"
    ref="formRef"
    v-model="isValid"
    :title="translations.title"
    :is-form-loading="isFormLoading"
    :is-button-loading="isLoading"
    :mode="isUpdate ? 'update' : 'create'"
    :action="action"
    @submit="(addNew: boolean) => isUpdate ? onUpdate() : submit(addNew)"
    @delete="onDelete"
    show-add-new
  >
    <transaction-form-alert
      :serverSideValidationResult="serverSideValidationResult"
      formType="account"
    />
    <v-row>
      <v-col cols="12" lg="12" v-if="showAccountSelector">
        <v-autocomplete
          v-bind="fieldDesign"
          :rules="[rules.required]"
          v-model="selectedAccount"
          :items="cashAccounts"
          :label="t('account_page.form.account_field')"
          item-title="name"
          item-value="id"
          return-object
          clearable
        />
      </v-col>
      <template v-if="!showAccountSelector || selectedAccount">
        <v-col cols="12" lg="6">
          <DateField
            v-model="form.date"
            :label="t('label.date')"
          />
        </v-col>
        <v-col cols="12" lg="6">
          <v-select
            v-bind="(fieldDesign as any)"
            :rules="[rules.required, rules.validTransaction(transactionTypeOptions.map((o) => o.value))]"
            :items="transactionTypeOptions"
            :label="t('label.transaction_type')"
            v-model="form.transactionType"
            :hint="translations.hints.transactionType[form.transactionType]"
          />
        </v-col>
        <v-col cols="12" lg="12" v-if="showRelatedAccount">
          <AccountSearchField
            :fieldDesign="fieldDesign"
            v-model="form.counterparty.id"
            :label="t('label.related_account')"
            :disabled="!canEditRelatedAccount"
            :hint="t('help.account')"
            :sourceAccount="account"
          />
        </v-col>
        <v-col cols="12">
          <div class="d-flex align-start ga-2">
            <AmountField
              v-model="form.account.grossAmount"
              v-model:currency-code-value="form.account.currencyCode"
              :label="t('label.sum')"
              :all-currencies="isConversion"
              :rules="[rules.required]"
            />
            <template v-if="showCounterparty">
              <div class="mt-4"> {{ arrow }} </div>
              <AmountField
                style="flex:1;"
                v-model="form.counterparty.grossAmount"
                v-model:currency-code-value="form.counterparty.currencyCode"
                :label="showRelatedAccount ? '' : t('label.sumTo')"
                :rules="counterpartyRules"
                :all-currencies="isConversion"
              />
            </template>
          </div>
        </v-col>
        <v-col cols="12" v-if="showFee">
          <div class="d-flex align-start ga-2">
            <FeeField
              v-model="form.account.fee"
              v-model:currency-code-value="form.account.feeCurrencyCode"
              :label="t('label.fees')"
              :all-currencies="isConversion"
            />
          </div>
        </v-col>
        <v-col cols="12" v-if="showTax">
          <FeeField
            v-model="form.account.tax"
            v-model:currency-code-value="form.account.taxCurrencyCode"
            :label="t('label.tax_withheld')"
          />
        </v-col>
        <v-col cols="12" lg="12">
          <v-text-field
            v-bind="(fieldDesign as any)"
            type="text"
            v-model="form.description"
            :label="t('label.description')"
          />
        </v-col>
      </template>
    </v-row>
    <template v-slot:before-actions>
      <transaction-summary
        :mode="'cash-account'"
        :color="'blue-lighten-3'"
        :total-costbase="form.value"
        :base-currency-code="portfolio?.currencyCode"
        :to-currency-code="exchangeRatePair[1]"
        :transaction="form"
        :amountType="'gross'"
      />
      </template>
  </form-container>
</template>
