import { computed, ref, watch, type ComputedRef, type Ref } from "vue";
import orderBy from "lodash/orderBy";
import { useAuthStore } from "./stores/authStore";
import { useI18n } from "vue-i18n";
import { HoldingType } from "~/enums";
import { getGroupName } from "./utils/instrument.utils";

export type Sorting = {
  key: string,
  direction: 'asc' | 'desc'
}

const defaultSorting = computed<Sorting>(() => {
  return {
    key: '',
    direction: 'desc'
  }
})


export function useReport(report: Ref<any>, sorting: ComputedRef<Sorting> = defaultSorting) {
  const authStore = useAuthStore();
  const { t, te } = useI18n();
  const toggleAllState = ref(false);
  let uniqueId = 0;

  function _isOpen (holding: any, isGroup: boolean) {
    if (isGroup) {
      return authStore.getUserPreference(`portfolio.group.open.${holding.key}`) ?? true;
    } else {
      return authStore.getUserPreference(`portfolio.holding.open.${holding.code}`)
    }
  }

  function handleGroup(group: any, level = 0) {
    const { key: sortingKey, direction: sortingDirection } = sorting.value;
    const items = group.items ?? []

    const investments = orderBy([...items, ...(group.groups ?? [])], sortingKey, sortingDirection)
      .reduce((_list: any[], holding: any) => {
        if (Array.isArray(holding.groups)) {
          holding = handleGroup(holding, level + 1);
        }
        holding.isOpen = holding.isOpen ?? _isOpen(holding, holding.isGroup) ?? false;
        holding.level = level + 1;
        holding.displayTitle = holding.code;
        holding.uuid = holding.eid || holding.id;
        _list.push(holding);
        if (holding.consolidation?.length > 0) {
          orderBy(holding.consolidation, sortingKey, sortingDirection).forEach((subHolding: any) => {
            _list.push({
              ...subHolding,
              isHidden: holding.isOpen !== true,
              isConsilidation: true,
              parent: holding,
              level: level + 2,
              displayTitle: holding.portfolios.length > 1 ? `${subHolding.code} (${subHolding.portfolios[0].name})` : subHolding.code,
              uuid: subHolding.id || subHolding.eid
            });
          });
        }
        return _list;
      }, [])

    const isOpen = _isOpen(group, true) ?? true;
    
    return {
      uuid: group.name,
      ...group,
      level,
      isOpen,
      isGroup: true,
      title: getGroupName(group.name),
      items: investments,
    }
  }

  const groups = computed(() => {
    const { key: sortingKey, direction: sortingDirection } = sorting.value;
    const groupList = (report.value?.groups ?? []).map((g: any) => handleGroup(g))
    const ordered = orderBy(groupList, sortingKey, sortingDirection)
    return ordered
  });

  function flattenGroup(group: any) {
    if (!group.isGroup) {
      return [group];
    }

    const items = (group.items ?? []).flatMap((holding: any) => {
      holding.group = group;
      return flattenGroup(holding)
    });

    return [group, ...items]
  }

  function removeInvalid (group: any) {
    if (!group.isGroup) {
      if (!group.id && group.type === HoldingType.Cash) {
        return null;
      } else if (group.isConsilidation && group.type !== HoldingType.Cash) {
        return null;
      }
      return group;
    }

    const items = (group.items ?? []).map((holding: any) => {
      return removeInvalid(holding)
    });

    group.items = items.filter((holding: any) => !!holding);

    return group;
  }

  const itemsFalattened = computed(() => {
    return groups.value.flatMap(flattenGroup)
  })

  const groupsFlattened = computed<{ value: number, title: string, name: string }[]>(() => {
    if (report.value?.groups?.length === 1 && report.value?.groups?.[0]?.key === 'none.all') {
      return report.value?.groups[0].items;
    }
    return groups.value.map(removeInvalid)
  });

  const flatList = computed(() => {
    const finalList = groups.value.reduce((list: any[], group: any) => {
      list.push(...group.items.flatMap((holding: any) => {
        holding.uuid = holding.instrumentType !== 'cash' ? holding.code : holding.id;
        return holding
      }));
      return list;
    }, []).filter(x => x.uuid)

    return Object.values(finalList.reduce((list: any[], holding: any) => {
      if (!list[holding.uuid] || list[holding.uuid].quantity < holding.quantity) {
        list[holding.uuid] = holding;
      }
      return list;
    }, {}));
  })

  const topN = (n: number, key: string) => {
    return itemsFalattened.value
      .filter((holding: any) => !holding.isPartial && holding.type === HoldingType.Investment && !holding.isGroup && !holding.parent)
      .sort((a: any, b: any) => b.openAndClosedPositions[key] - a.openAndClosedPositions[key])
      .slice(0, n);
  }

  const bottomN = (n: number, key: string) => {
    return itemsFalattened.value
      .filter((holding: any) => !holding.isPartial && holding.type === HoldingType.Investment && !holding.isGroup && !holding.parent)
      .sort((b: any, a: any) => b.openAndClosedPositions[key] - a.openAndClosedPositions[key])
      .slice(0, n);
  }

  const open = (holding: any, state?: boolean) => {
    holding.isOpen = state ?? !holding.isOpen;
    authStore.setUserPreference(`portfolio.holding.open.${holding.code}`, holding.isOpen);
  }
  
  const openGroup = (group: any, state?: boolean) => {
    group.isOpen = state ?? !group.isOpen;
    authStore.setUserPreference(`portfolio.group.open.${group.key}`, group.isOpen);
  }

  const isHoldingVisible = (holding: any): boolean => {
    if (holding.group) {
      return isHoldingVisible(holding.group) && holding.group.isOpen !== false && (holding.isGroup || holding.isHidden !== true)
    }
    return true;
  }

  const toggleAll = () => {
    itemsFalattened.value.forEach((holding) => {
      if (holding.isGroup) {
        openGroup(holding, !toggleAllState.value);
      } else {
        open(holding, !toggleAllState.value);
      }
    });
    toggleAllState.value = !toggleAllState.value;
  }

  const groupClass = (item: any) => {
    return {
      'bg-primary-lighten-3': item.level === 0, 
      'bg-primary-lighten-4': item.level === 1, 
      [`level-${item.level}`]: true
    }
  }

  watch(itemsFalattened, (newVal) => {
    const areAllOpen = newVal.every((holding) => holding.isOpen !== false);
    if (areAllOpen) {
      toggleAllState.value = true;
    } else {
      toggleAllState.value = false;
    }
  }, { deep: true })
  
  return {
    toggleAllState,
    groups,
    toggleAll,
    groupClass,
    open,
    openGroup,
    groupsFlattened,
    itemsFalattened,
    flatList,
    isHoldingVisible,
    topN,
    bottomN
  }
}
