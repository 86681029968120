<script setup lang="ts">
import { useAuthStore } from '@/stores/authStore';
import { usePortfolioStore } from '@/stores/portfolioStore';
import dayjs from 'dayjs';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const emit = defineEmits(['onClose']);

const authStore = useAuthStore();
const portfolioStore = usePortfolioStore();
const router = useRouter();
const { t, locale } = useI18n();

const getPortfolioById = (id: number) => {
  return (portfolioStore.portfolios.value || []).find((portfolio) => portfolio.id === id);
};

const getAgoFromDate = (date: string) => {
  if (!date) return;
  // use dayjs
  const now = dayjs();
  const then = dayjs(date);
  const days = now.diff(then, 'days');
  const hours = now.diff(then, 'hours');
  const minutes = now.diff(then, 'minutes');
  const seconds = now.diff(then, 'seconds');

  if (days > 0) {
    return t('notifications.daysAgo', { days });
  } else if (hours > 0) {
    return t('notifications.hoursAgo', { hours });
  } else if (minutes > 0) {
    return t('notifications.minutesAgo', { minutes });
  } else {
    return t('notifications.secondsAgo', { seconds });
  }
}

const notifications = computed(() => {
  return authStore.notifications
    .filter((notification: any) => notification.status < 100)
    .map((notification: any) => {
      const title = notification.title[locale.value] || notification.title;
      const content = notification.content[locale.value] || notification.content;

      return {
        id: notification.id,
        key: notification.idKey,
        title: t(title) || title,
        content:  t(content, { ...notification.data || {}}) || content,
        data: notification.data,
        time: getAgoFromDate(notification.date),
        icon: notification.icon,
        color: notification.color,
        readable: notification.readable,
        deletable: notification.deletable,
      };
    });
});

const onNotificationClick = (notification: any) => {
  if (notification.data.to) {
    router.push(notification.data.to);
    emit('onClose');
  }
};

const markAsRead = (notification: any) => {
  if (notification.readable) {
    authStore.markNotificationAsRead(notification.id, 1);
  }
};

const markAsDeleted = (notification: any) => {
  if (notification.deletable) {
    authStore.markNotificationAsRead(notification.id, 100);
  }
};
</script>

<template>
  <perfect-scrollbar style="max-height: 650px">
    <v-list class="py-0" lines="three" rounded="md">
      <template v-for="notification in notifications">
        <v-list-item :data-action="markAsRead(notification)" :value="notification.id" color="secondary" class="no-spacer" density="compact" @click="onNotificationClick(notification)">
          <template v-slot:prepend>
            <v-avatar size="30" class="mr-3 py-2" v-if="notification.icon">
              <v-progress-circular
                v-if="notification.icon === 'progress'"
                :width="3"
                color="green"
                indeterminate
              />
              <v-icon :color="notification.color" v-else size="30">{{ notification.icon }}</v-icon>
            </v-avatar>
          </template>
          <div class="d-inline-flex align-center ga-2 justify-space-between w-100">
            <h6 class="text-h4 font-weight-medium">
              {{ notification.title }}
            </h6>
            <div class="d-flex align-center ga-1">
              <span class="text-caption">
                {{ notification.time }}
              </span>
              <v-btn
                v-if="notification.deletable"
                variant="text"
                icon="mdi-close"
                density="compact"
                size="small"
                @click.stop="markAsDeleted(notification)"
              />  
            </div>
          </div>
          <p class="text-body-1 mt-1">
            <template v-if="notification.key === 'unconfirmedTransactions'">
              <span class="mr-1" v-html="$t('notifications.unconfirmedTransactions.content', { total: notification.data.totalCount })" />
              <template v-for="(portfolio, index) in notification.data.portfolios">
                <RouterLink class="text-blue" :to="{name: 'portfolio-accounts', params:{portfolioId:portfolio.id}}">{{ getPortfolioById(portfolio.id)?.name }}</RouterLink>{{ index < notification.data.portfolios.length - 1 ? ', ' : '' }}
              </template>
            </template>
            <template v-else>
              <span v-html="notification.content" />
            </template>
          </p>
          
        </v-list-item>
        <v-divider v-if="notification.id !== notifications[notifications.length - 1].id" class="my-1"></v-divider>
      </template>
    </v-list>
    <div v-if="notifications.length === 0" class="text-center py-4">
      {{ t('notifications.noNotifications') }}
    </div>
  </perfect-scrollbar>
</template>
